import { useEffect } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
//
import AllReports from './pages/AllReports';
import FindReports from 'pages/FindReports';
import AllTests from './pages/AllTests';
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import DashboardAppPage from './pages/DashboardAppPage';
import DashboardUserPage from './pages/DashboardUserPage';
import DashboardSuperUserPage from './pages/DashboardSuperUserPage';
import InterpretationUtility from './pages/InterpretationUtility';
import Interpretation from './layouts/manual-Interpretation';
import Custominterpretation from './pages/customInterpretation';
import Profile from 'pages/Profile';
import Finance from 'pages/Finance';
import ManageInternalUsers from 'pages/ManageInternalUsers';
import ManageB2BUsers from 'pages/ManageB2BUsers';
import ManageAssociatedUsers from 'pages/ManageAssociatedUsers';
import UserOrder from 'pages/UserOrder';
import DeviceReports from 'pages/DeviceReports';
import InvoiceHistory from 'pages/InvoiceHistory';
import ProcuredDevices from 'pages/ProcuredDevices';
import Managebusinessuser from 'pages/ManageBusinessUsers';
import ViewB2BDashboard from 'pages/ViewB2BDashboard';
import AppOnboardedUsers from 'pages/AppOnboardedUsers';
import SubscriptionPlans from 'pages/SubscriptionPlans';
import BusinessClients from 'pages/BusinessClients';
import MyClinics from 'pages/MyClinics';
import PlanUsers from 'pages/PlanUsers';
import ExtendPlanUsers from 'pages/ExtendPlanUsers';
import RDReports from 'pages/RDReports';
import GuestUsers from 'pages/GuestUsers';
import AssociatedUsersCount from 'pages/AssociateUsersCount';
import { useAuthDispatch } from 'components/AuthStateProvider';
import DeviceUsage from 'pages/DeviceUsage';

import { requestNotificationPermission, handleForegroundMessages } from './firebaseMessaging';


// ----------------------------------------------------------------------
export default function Router({ }) {
  const { pathname } = useLocation();
  const { loading, account, setFcmPushToken } = useAuthDispatch();

  const initFCMToken = async () => {
    const fcmToken = await requestNotificationPermission();
    handleForegroundMessages();
    if (fcmToken) await setFcmPushToken(fcmToken);
  }

  useEffect(() => {
    if (account && pathname === '/dashboard') {
      initFCMToken();
    }
  }, [loading, pathname]);

  if (loading) return;

  let children = [];
  switch (account?.userType) {
    case 'user':
      children = [
        { path: 'userDashboard', element: <DashboardUserPage /> },
        { path: 'allTests', element: <AllTests /> },
        { path: 'userOrder', element: <UserOrder /> }
      ];
      break;
    case 'marketingteam':
      children = [
        { path: 'marketingteamDashboard', element: <AppOnboardedUsers /> },
        { path: 'app-guest-user', element: <GuestUsers /> },
        { path: 'profile', element: <Profile /> },
      ];
      break;
    case 'managementteam':
      children = [
        { path: 'managementteamDashboard', element: <SubscriptionPlans /> },
        { path: 'business-clients', element: <BusinessClients /> },
        { path: 'my-clinics', element: <MyClinics /> },
        { path: 'plan-users', element: <PlanUsers /> }
      ];
      break;
    case 'r&dteam':
      children = [
        { path: 'r&dteamDashboard', element: <BusinessClients title="View client's reports" /> },
        { path: 'reports', element: <RDReports /> },
        { path: 'clinics', element: <MyClinics /> },
        { path: 'reports/:id', element: <RDReports />}
      ];
      break;
    case 'expert':
      children = [
        { path: 'expertDashboard', element: <DashboardAppPage /> },
        { path: 'allReports', element: <AllReports /> },
        { path: 'interpretationUtility', element: <InterpretationUtility /> },
        { path: 'interpretation', element: <Interpretation /> },
        { path: 'profile', element: <Profile /> },

      ];
      break;
    case 'b2b':
      children = [
        { path: 'b2bDashboard', element: <DashboardUserPage /> },
        { path: 'allTests', element: <AllTests /> },
        { path: 'profile', element: <Profile /> },
        { path: 'associated-users', element: <ManageAssociatedUsers /> },
        { path: 'associate-users-count', element: <AssociatedUsersCount/>},
        { path: 'business-users', element: <Managebusinessuser /> },
        { path: 'procured-devices', element: <ProcuredDevices /> },
        { path: 'deviceusage', element: <DeviceUsage /> },
        { path: 'devicereports/:id', element: <DeviceReports /> },
      ]
      break;
    case 'b2b-user':
      children = [
        { path: 'b2b-userDashboard', element: <DashboardUserPage /> },
        ...(account?.settings?.can_view_data ? [{ path: 'allTests', element: <AllTests /> }] : []),
        ...(account?.settings?.can_manage_business_admin ? [{ path: 'business-users', element: <Managebusinessuser /> }] : []),
        ...(account?.settings?.can_manage_associated_user ? [{ path: 'associated-users', element: <ManageAssociatedUsers /> }] : []),
        { path: 'profile', element: <Profile /> }
      ]
      break;
    case 'admin':
      children = [
        { path: 'adminDashboard', element: <DashboardSuperUserPage /> },
        { path: 'allReports', element: <AllReports /> },
        { path: 'find-reports', element: <FindReports />},
        { path: 'profile', element: <Profile /> },
        { path: 'internal-users', element: <ManageInternalUsers /> },
        { path: 'clients', element: <ManageB2BUsers /> },
        { path: 'b2b-dashboard', element: <ViewB2BDashboard /> },
        { path: 'app-onboarded-user', element: <AppOnboardedUsers /> },
        { path: 'app-guest-user', element: <GuestUsers /> },
        { path: 'subscription-plans', element: <SubscriptionPlans/> },
        { path: 'plan-users', element: <PlanUsers /> },
        { path: 'extend-plan-users', element: <ExtendPlanUsers /> },
        { path: 'my-clinics', element: <MyClinics /> },
        { path: 'business-clients', element: <BusinessClients /> },
        { path: 'ecg-analysis', element: <RDReports /> },
      ];
      break;

    case 'superuser':
      children = [
        { path: 'adminDashboard', element: <DashboardSuperUserPage /> },
        { path: 'allReports', element: <AllReports /> },
        { path: 'profile', element: <Profile /> },
        { path: 'internal-users', element: <ManageInternalUsers /> },
        { path: 'clients', element: <ManageB2BUsers /> },
        { path: 'finance', element: <Finance /> },
        { path: 'finance/invoiceHistory', element: <InvoiceHistory /> },
        { path: 'b2b-dashboard', element: <ViewB2BDashboard /> },
        { path: 'app-onboarded-user', element: <AppOnboardedUsers /> },
        { path: 'app-guest-user', element: <GuestUsers /> },
        { path: 'subscription-plans', element: <SubscriptionPlans/> },
        { path: 'plan-users', element: <PlanUsers /> },
        { path: 'my-clinics', element: <MyClinics /> },
        { path: 'business-clients', element: <BusinessClients /> },
        { path: 'ecg-analysis', element: <RDReports /> },
      ];
      break;
    default:
      break;
  }

  const routes = useRoutes([
    {
      path: `${process.env.REACT_APP_HOMEPAGE}dashboard`,
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to={`${process.env.REACT_APP_HOMEPAGE}${account?.authorization ? `dashboard/${account?.userType === 'superuser' ? 'admin' : account?.userType}Dashboard` : 'login'}`} />, index: true },
        ...children,
      ],
    },
    {
      path: `${process.env.REACT_APP_HOMEPAGE}custominterpretation/:id`,
      element: <Custominterpretation />
    },
    // { 
    //   path: `${process.env.REACT_APP_HOMEPAGE}dashboard/reports/:id`, 
    //   element: <RDReports /> 
    // },
    {
      path: `${process.env.REACT_APP_HOMEPAGE}interpretation`,
      element: <Interpretation />
    },
    {
      path: `${process.env.REACT_APP_HOMEPAGE}login`,
      element: <LoginPage />,
    },
    {
      path: `${process.env.REACT_APP_HOMEPAGE}`,
      element: account?.authorization ? <Navigate to={`${process.env.REACT_APP_HOMEPAGE}${`dashboard/${account?.userType === 'superuser' ? 'admin' : account?.userType}Dashboard`}`} /> : <LoginPage />,
      index: true
    },
    {
      path: `${process.env.REACT_APP_HOMEPAGE}404`,
      element: <Page404 />
    },
    {
      path: '*',
      element: <Navigate to={account?.authorization ? `${process.env.REACT_APP_HOMEPAGE}404` : `${process.env.REACT_APP_HOMEPAGE}login`} replace />,
    },

  ]);
  return routes;
}
