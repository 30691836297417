import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import exportFromJSON from 'export-from-json';
import { DataGrid, GridToolbarContainer, GridToolbarExport} from '@mui/x-data-grid';
import DevicetestData from 'devicedata/DevicetestData';
// @mui
import {
  Card,
  Box,
  Stack,
  Container,
  Typography,
  Select,
  MenuItem,
  FormControl
} from '@mui/material';
import useResponsive from '../hooks/useResponsive';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { styled } from '@mui/system';
import { useSnackbar } from '../components/snackbar';

import { LoadingButton } from '@mui/lab';
import CircularProgress from '@mui/material/CircularProgress';
import { getAppOnboardedUsers } from 'api/users';
import { copyToClipboard } from 'utils/Utils';
//-----------------------------------------------------------------------
const OverflowTypography = styled(Typography)(() => ({
  maxWidth: 150, // percentage also works
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis"
}));

export default function AppOnboardedUsers() {
  const { enqueueSnackbar } = useSnackbar();
  const [{ startDate, endDate }, setDates] = useState({ startDate: dayjs(), endDate: dayjs() });
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [downloading, setDownloading] = useState(false);
  const [filterKey, setFilterKey] = useState('created_at');
  const [channels, setChannels] = useState([]);
  const [selectedChannel, setSelectedChannel] = useState('all');

  useEffect(() => {
    fetchData();
  }, [startDate, endDate, filterKey]);

  useEffect(() => {
    if (selectedChannel !== 'all') setFilteredData(data?.filter(item => item?.channel === selectedChannel));
    else setFilteredData([]);
  }, [selectedChannel]);

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await getAppOnboardedUsers({
        startDate: dayjs(startDate).format("DD MMM, YYYY"),
        endDate: dayjs(endDate).format("DD MMM, YYYY"),
        filterKey,
      });
  
      if (response?.success) {
        const devicetestdata = DevicetestData(); 
        const uniqueChannels = Array.from(new Set(devicetestdata.map(obj => obj.channel)));
        setChannels(uniqueChannels);
        const usersData = response?.data?.map((user, index) => {
          const deviceChannelInfo = Object.values(devicetestdata).find(
            (device) => device.channelDeviceIds === user.deviceId
          ) || null; 
  
          return {
            ...user,
            sno: index + 1,
            userEmail: user?.userEmail || "--",
            name: user?.firstName + (user?.lastName ? " " + user?.lastName : ""),
            created_at: user?.created_at
              ? dayjs(parseInt(user?.created_at)).format("DD MMM, YYYY hh:mm A")
              : "--",
            updated_at: user?.updated_at
              ? dayjs(parseInt(user?.updated_at)).format("DD MMM, YYYY hh:mm A")
              : "--",
            deviceLastConnectedAt: user?.deviceLastConnected
              ? dayjs(parseInt(user?.deviceLastConnected)).format("DD MMM, YYYY hh:mm A")
              : "--",
            recentDeviceId: user?.deviceId || "--",
            deviceName: deviceChannelInfo?.deviceType || "--",
            channel: deviceChannelInfo?.channel || "--", 
            deviceIds: user?.deviceIds?.length ? user.deviceIds.join(", ") : user?.deviceId,
          };
        });
        setData(usersData);
        if (selectedChannel !== 'all') setFilteredData(usersData?.filter(item => item?.channel === selectedChannel).map((item, index) => { return { ...item, sno: (index + 1) } }));
      } else {
        enqueueSnackbar(response?.message, { variant: "error" });
      }
    } catch (e) {
      console.log(e);
      enqueueSnackbar("Unable to fetch users.", { variant: "error" });
    } finally {
      setLoading(false);
    }

  };
  
  const onCopyClick = (text) => (e) => {
    copyToClipboard(text);
    enqueueSnackbar('Copied to clipboard.', { variant: 'success' })
  };

  const downloadCSV = async (e) => {
    setDownloading(true);
    const downloadableData = selectedChannel !== 'all' ? filteredData : data;
    if (downloadableData?.length) {
      const users = downloadableData?.map(item => ({
        'Name': item?.name,
        'Phone No': item?.phoneNumber,
        'Email': item?.userEmail,
        'Created At': item?.created_at,
        'Updated At': item?.updated_at,
        'Recent Connected Device': item?.recentDeviceId,
        'Recent Connected At': item?.deviceLastConnectedAt,
        'Channel': item?.channel,
        'Device Name': item?.deviceName,
        'Total Devices Used': item?.deviceIds?.length ? item?.deviceIds?.length : (item?.recentDeviceId ? 1 : 0),
      }));

      const fileName = `users-onboarded-on-app-date-${startDate !== endDate ? dayjs(startDate).format("DD-MM-YY") + "_to_" + dayjs(endDate).format("DD-MM-YY") : dayjs(startDate).format("DD-MM-YY") }`
      const exportType = exportFromJSON.types.csv
      exportFromJSON({ data: users, fileName, exportType });
    } else {
      enqueueSnackbar('No data available to export.', { variant: "error" });
    }
    setDownloading(false);
  }

  const handleStartDateChange = (sDate, eDate) => {
    if (sDate) setDates({ startDate: sDate, endDate  })
    if (eDate) setDates({ startDate, endDate: eDate })
  }

  const columns = [
    { field: 'id', headerName: 'ID', hide: true },
    { field: 'sno', headerName: '#', hide: true, width: 60 },
    {
      field: 'name',
      headerName: 'Name',
      width: 200,
    },
    {
      field: 'phoneNumber',
      headerName: 'Phone No',
      width: 180,
    },
    {
      field: 'created_at',
      headerName: 'Created At',
      width: 200,
    },
    {
      field: 'updated_at',
      headerName: 'Updated At',
      width: 200,
    },
    {
      field: 'recentDeviceId',
      headerName: 'Recent Device',
      width: 300,
    },
    {
      field: 'channel',
      headerName: 'Channel',
      width: 100,
    },
    {
      field: 'deviceLastConnectedAt',
      headerName: 'Connected At',
      width: 300,
    },
    {
      field: 'deviceIds',
      headerName: 'Device IDS',
      width: 300,
    },
    {
      field: 'userEmail',
      headerName: 'Email',
      width: 200,
    },
  ];
  return (
    <>
      <Helmet>
        <title>Onboarded users on APP | Sunfox Experts</title>
      </Helmet>

      <Container sx={{ marginTop: 3 }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
            <Stack direction="column" >
                <Typography variant="h4" gutterBottom>
                    Onboarded users on APP
                </Typography>
                { data?.length ? <Typography variant="div" gutterBottom>
                    Users onboarded: <b>{ data?.length }</b>
                </Typography> : null }
            </Stack>
          <Stack direction="row" spacing={2} alignItems="center">
            <Stack direction="row" spacing={1}>
                <Box>
                  <FormControl fullWidth>
                    <Select 
                      labelId="channel-select-small-label" 
                      sx={{ width: '100px' }}
                      defaultValue={ selectedChannel }
                      onChange={(event) => setSelectedChannel(event.target.value) }
                    >
                      <MenuItem value="all" selected >All</MenuItem>
                      {channels?.map(channel => (
                        <MenuItem value={channel} >{ channel }</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
                <Box>
                  <FormControl fullWidth>
                    <Select 
                      labelId="filter-select-small-label" 
                      sx={{ width: '160px' }}
                      defaultValue={ filterKey }
                      onChange={(event) => setFilterKey(event.target.value) }
                    >
                      <MenuItem value="created_at" selected >Created At</MenuItem>
                      <MenuItem value="updated_at">Updated At</MenuItem>
                      <MenuItem value="deviceLastConnected">Connected At</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
                <DatePicker
                    sx={{ width: '160px' }}
                    label="Start Date"
                    value={ startDate }
                    format='DD/MM/YYYY'
                    maxDate={endDate}
                    onChange={(date) => handleStartDateChange(date)}
                />
                <DatePicker
                    sx={{ width: '160px' }}
                    label="End Date"
                    value={ endDate }
                    format='DD/MM/YYYY'
                    minDate={ startDate }
                    maxDate={ dayjs() }
                    onChange={(date) => handleStartDateChange(null, date)}
                />
            </Stack>
            <Box>
                <LoadingButton
                    loading={ downloading }
                    color="info"
                    variant="contained"
                    onClick={ downloadCSV }

                    sx={{ borderRadius: "20px" }}>
                    Download CSV
                </LoadingButton>
            </Box>
          </Stack>
        </Stack>
        <Card>
          <Box>
            <Box>
              {loading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '30px', alignItems: 'center' }}>
                  <CircularProgress />
                </Box>
              ) : (
                <DataGrid
                  columnVisibilityModel={{
                    id: false,
                  }}
                  rows={ selectedChannel !== 'all' ? filteredData : data}
                  columns={ columns }
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 25,
                      },
                    },
                  }}
                  
                  pageSizeOptions={[25]}
                  autoHeight
                />
              )}
            </Box>
          </Box>
        </Card>
      </Container>
    </>
  );
}
