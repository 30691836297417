import { Helmet } from 'react-helmet-async';
import { useMemo } from 'react';
import { filter } from 'lodash';
import exportFromJSON from 'export-from-json';
import { useEffect, useState } from 'react';
import Label from 'components/label/Label';
import dayjs from 'dayjs';
import { LoadingButton } from '@mui/lab';
import CircularProgress from '@mui/material/CircularProgress';
import { getAllReports, updateReportStatus } from 'api/reports';
import * as React from 'react';
import { getEcgData } from 'api/reports';
import { copyToClipboard, downloadFromURI } from 'utils/Utils';
// @mui
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Snackbar,
  Card,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Box,
  Alert,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  TableFooter,
  Tooltip,
} from '@mui/material';
import useResponsive from '../hooks/useResponsive';

import { Download, Share, ContentCopy } from '@mui/icons-material';
// components
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';

import { height, styled } from '@mui/system';
import { useSnackbar } from '../components/snackbar'
import { useAuthDispatch } from 'components/AuthStateProvider';
//-----------------------------------------------------------------------
const OverflowTypography = styled(Typography)(() => ({
  maxWidth: 150, // percentage also works
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis"
}));


function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function AllTests({ getMyClinicsPhoneNumber }) {
  const { enqueueSnackbar } = useSnackbar();
  const isSmall = useResponsive('', 'xs');
  const isLg = useResponsive('up', 'md');
  const { account } = useAuthDispatch();

  const [open, setOpen] = useState(null);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [completeSelected, setCompleteSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [data, setData] = useState([]);
  const [dataCount, setDataCount] = useState(null);

  const [status, setstatus] = useState('');

  const [loading, setLoading] = useState(true);
  const [downloading, setDownloading] = useState(false);

  const [startDate, setStartDate] = useState(dayjs());
  const [endDate, setEndDate] = useState(dayjs());

  const [ButtonLoadingStatus, setButtonLoadingStatus] = useState(false);
  const [downloadButtonLoadingStatus, setDownloadButtonLoadingStatus] = useState(false);

  const [rowLoading, setRowLoading] = useState([]);
  const [rowDownloadLoading, setRowDownloadLoading] = useState([]);

  const [openPaymentAlert, setOpenPaymentAlert] = useState(false);

  const [paymentPayload, setPaymentPayload] = useState({});

  const [interpretationStatusFilter, setInterpretationStatusFilter] = useState('all');


  // ----------------------------------------------------------------------
  const TABLE_HEAD = getMyClinicsPhoneNumber
    ? [
      { id: 'name', label: 'Name', alignRight: false, minWidth: '150px' },
      { id: 'report_type', label: 'Report Type', alignRight: false, minWidth: '120px' },
      { id: 'report_timestamp', label: 'Taken At', alignRight: false, minWidth: '150px' },
      { id: 'interpretation_report_status', label: 'Findings', alignRight: false }
    ]
    : [
      { id: 'name', label: 'Name', alignRight: false, minWidth: '150px' },
      { id: 'report_type', label: 'Report Type', alignRight: false, minWidth: '120px' },
      { id: 'report_timestamp', label: 'Taken At', alignRight: false, minWidth: '150px' },
      { id: 'interpretation_requested_at', label: `${(status === 'completed' ? 'Completed' : 'Requested')} At`, alignRight: false, minWidth: '160px' },
      ...(status === 'completed' || !status ? [{ id: 'interpretation_request_status', label: 'Findings', alignRight: false }] : []),
      { id: 'interpretation_request_status', label: 'Status', alignRight: false },
      { id: ' ', label: ' ', alignRight: false, minWidth: '100px' },
    ];
  const handleStartDateChange = (date, type) => {
    if (!type) setStartDate(date);
    else setEndDate(date)
    setLoading(true);
  };

  const formatDate = (date) => {
    return date ? dayjs(date).format('DD MMM, YYYY') : null;
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (!(selected?.length || completeSelected?.length)) {
      const newSelectedIds = data
        .filter((row) => !row?.interpretation_request_status)
        .map((row) => row.id);
      const completedIds = data
        .filter((row) => row?.interpretation_request_status === 'completed')
        .map((row) => row.id);
      setSelected(newSelectedIds);
      setCompleteSelected(completedIds);
    } else {
      setSelected([]);
      setCompleteSelected([]);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handlePaymentPopupClose = (event) => {
    setOpenPaymentAlert(false);
    setRowLoading([]);
  };

  const handlePaymentPopup = (event) => {
    setOpenPaymentAlert(false);
    const options = {
      prefill: {
        contact: paymentPayload.notes?.phoneNumber
      },
      modal: {
        ondismiss: () => {
          document.body.style = '';
        },
      },
      handler: function (res) {
        if (res.razorpay_payment_id && res.razorpay_order_id && res.razorpay_signature) {
          const updatedData = data.map(item => {
            if (paymentPayload?.ids?.includes(item.id)) {
              return { ...item, interpretation_request_status: "requested" };
            }
            return item;
          });

          setData(updatedData);
          enqueueSnackbar('Payment successful', { variant: 'success' });
          setButtonLoadingStatus(false);
        }

      },
      key: paymentPayload.razorpay_public_key,
      order_id: paymentPayload.id,
    }
    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
    paymentObject.on('payment.failed', function (response) {
      enqueueSnackbar(response.error.description || 'Payment failed', { variant: 'error' });
      setButtonLoadingStatus(false);
    });
    setSelected([]);
    setRowLoading([]);
    setLoading(false);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data?.length) : 0;

  const changeFilter = (changeFilter,) => {
    setInterpretationStatusFilter(selectedValue);
  }

  const filteredUsers = useMemo(() => {
    if (status === 'completed') {
      return applySortFilter(
        data.filter(row => {
          if (interpretationStatusFilter === 'all') {
            return true;
          } else {
            return row?.interpretation_report_status === interpretationStatusFilter;
          }
        }),
        getComparator(order, orderBy),
        filterName
      );
    } else {
      return applySortFilter(data, getComparator(order, orderBy), filterName);
    }
  }, [data, order, orderBy]);


  const isNotFound = !filteredUsers.length && !!filterName;


  useEffect(() => {
    setPage(0);
    fetchData();
  }, [status, startDate, endDate]);

  const fetchData = async () => {
    setLoading(true);
    const phoneNumber = getMyClinicsPhoneNumber || account?.phoneNumber;

    const role = getMyClinicsPhoneNumber ? 'user' : 'defaultRole';

    const response = await getAllReports({
      id: phoneNumber,
      startDate: formatDate(startDate),
      endDate: formatDate(endDate),
      status: status,
      role: role,
    });
    setLoading(false);
    if (response?.success) {
      const filteredData = response?.data?.map(row => {
        return { ...row, name: `${row?.user_data?.first_name} ${row?.user_data?.last_name}` };
      });
      setData(filteredData || []);
      setDataCount(response?.counts);
    } else {
      enqueueSnackbar(response.message, { variant: 'error' });
    }
  };


  const handleRowButtonClick = async (event, reportIds, row, rowInter) => {
    try {
      if (selected?.length) setButtonLoadingStatus(true);
      setRowLoading(reportIds);
      const response = await updateReportStatus({
        ids: reportIds,
      });

      if (response) {
        if (response?.data?.length && response?.data[0]?.payment_data) {
          setOpenPaymentAlert(true);
          setPaymentPayload(response?.data[0]?.payment_data);
        }
        else {
          fetchData();
          enqueueSnackbar(response.message, { variant: 'success' })
        }
      } else {
        enqueueSnackbar(response.message, { variant: 'error' })
      }
      setSelected([]);
      setRowLoading([]);
      setButtonLoadingStatus(false);
      setLoading(false)
    } catch (error) {
      console.error("Error occurred:", error);
      enqueueSnackbar(error?.message || "Something went wrong.", { variant: 'error' });
      setSelected([]);
      setRowLoading([]);
      setButtonLoadingStatus(false);
      setLoading(false);
    }
  };

  const downloadPDF = async (event, ids) => {
    try {
      if (selected?.length) setDownloadButtonLoadingStatus(true);
      setRowDownloadLoading(ids);
      for (const id of ids) {
        const reportResponse = await getEcgData({ id });

        if (reportResponse?.success) {
          const reportdata = reportResponse?.data;
          const fileName = `${reportdata?.user_data?.first_name}-${reportdata?.user_data?.last_name}-${dayjs(reportdata?.report_timestamp).format('DD-MM-YYYY-HH-mm')}${reportdata?.interpretation_data?.report_status ? `-${reportdata?.interpretation_data?.report_status}` : ''}`;
          downloadFromURI(`data:application/pdf;base64,${reportdata?.ReportPdf}`, fileName)
          // setSnackAlert({ snackOpen: true, snackMsg: `Report PDF ${(ids?.length > 1 ? `${(ids.indexOf(id) + 1)}/${ids.length}` : '')} downloaded suceessfully.`, severity: 'success' });
          enqueueSnackbar(`Report PDF ${(ids?.length > 1 ? `${(ids.indexOf(id) + 1)}/${ids.length}` : '')} downloaded suceessfully.`, { variant: 'success' })

        }
      }
    } catch (error) {
      enqueueSnackbar(error, { variant: 'error' })
    }
    setRowDownloadLoading([]);
    setDownloadButtonLoadingStatus(false);
    setCompleteSelected([])
  };

  const cancelAllSelected = async () => {
    setSelected([]);
    setCompleteSelected([]);
  };

  const handleClick = (checked, row) => {
    if (checked) {
      if (row?.interpretation_request_status === "completed") setCompleteSelected((prevIds) => [...prevIds, row?.id])
      else if (!row?.interpretation_request_status) setSelected((prevIds) => [...prevIds, row?.id])
    } else {
      if (row?.interpretation_request_status === "completed") setCompleteSelected((prevIds) => prevIds.filter((existingId) => existingId !== row?.id))
      else if (!row?.interpretation_request_status) setSelected((prevIds) => prevIds.filter((existingId) => existingId !== row?.id));
    }
  };

  const handleDatastatus = (newStatus, loading) => {
    setstatus(newStatus);
    setLoading(loading);
  };

  const onCopyClick = (text) => (e) => {
    copyToClipboard(text);
    enqueueSnackbar('Copied to clipboard.', { variant: 'success' })
  };

  const downloadCSV = async (e) => {
    setDownloading(true);
    // const role = getMyClinicsPhoneNumber ? 'user' : 'defaultRole';
    // const response = await getAllReports({
    //   id: getMyClinicsPhoneNumber || account?.phoneNumber,
    //   startDate: formatDate(startDate),
    //   endDate: formatDate(endDate || startDate),
    //   status: 'all',
    //   role: role
    // });
    // setDownloading(false);
    // if (response?.success && response?.data?.length) {
    const reports = data?.map(report => ({
      'Report Type': report?.report_type,
      'Phone No': report?.user_data?.phone_number || "-",
      'Patient Phone No': report?.user_data?.guest_phone_number || "-",
      'Patient Name': report?.user_data?.first_name + (report?.user_data?.last_name ? " " + report?.user_data?.last_name : ""),
      'Taken at': report?.report_timestamp ? dayjs(parseInt(report?.report_timestamp)).format("DD MMM, YYYY hh:mm A") : "",
      'Findings': report?.conclusions?.ecg_type ? report?.conclusions?.ecg_type : '--',
      ...(!getMyClinicsPhoneNumber ? {
        'Findings': report?.interpretation_report_status ? report?.interpretation_report_status : (report?.conclusions?.ecg_type ? report?.conclusions?.ecg_type : '--'),
        'Status': report?.interpretation_request_status || "",
        'Requested at': report?.interpretation_requested_at ? dayjs(parseInt(report?.interpretation_requested_at)).format("DD MMM, YYYY hh:mm A") : "",
        'Completed at': report?.interpretation_completed_at ? dayjs(parseInt(report?.interpretation_completed_at)).format("DD MMM, YYYY hh:mm A") : "",
      } : {})
    }));

    const fileName = `report-${startDate !== endDate ? dayjs(startDate).format("DD-MM-YY") + "_to_" + dayjs(endDate).format("DD-MM-YY") : dayjs(startDate).format("DD-MM-YY")}`
    const exportType = exportFromJSON.types.csv
    exportFromJSON({ data: reports, fileName, exportType });

    setDownloading(false);
    // }
  }


  return (
    <>
      <Helmet>
        <title> User | Sunfox Experts</title>
      </Helmet>

      <Container sx={{ marginTop: (isLg ? 0 : 1) }}>
        <Stack direction={isLg ? "row" : 'column'} alignItems="center" justifyContent="space-between" mb={2}>
          <Box>
            <Typography variant="h4" sx={{ marginBottom: 0 }} gutterBottom>
              ECG tests
            </Typography>
          </Box>
          {dataCount ?
            <Box>
              <Stack direction="row" alignItems="center" justifyContent="space-between" gap={1}>
                <Typography variant="div" sx={{ marginBottom: 0, fontWeight: 500 }} gutterBottom>
                  Total tests: <b>{dataCount?.totalTests || 0}</b>
                </Typography> |
                <Typography variant="div" sx={{ marginBottom: 0 }} color="#2065D1" gutterBottom>
                  Requested: <b>{dataCount?.totalRequested || 0}</b>
                </Typography> |
                <Typography variant="div" sx={{ marginBottom: 0 }} color="green" gutterBottom>
                  Completed: <b>{dataCount?.totalCompleted || 0}</b>
                </Typography>
                {!!account?.settings?.can_request_for_review && dataCount?.totalTests ? <>| <LoadingButton
                  loading={downloading}
                  color="info"
                  variant="contained"
                  onClick={downloadCSV}
                  sx={{ borderRadius: "20px" }}
                >
                  Download CSV
                </LoadingButton> </> : null}
                {/* <Chip
                    label={ 'Download CSV' }
                    color="info"
                    clickable
                    disabled={ downloading }
                    variant={"filled"}
                    sx={{ cursor: 'pointer' }}
                    onClick={ downloadCSV }
                  /> */}
              </Stack>
            </Box> : null}
        </Stack>
        <Dialog
          open={openPaymentAlert}
          onClose={handlePaymentPopupClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Payment for interpretation
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Typography color={'black'} gutterBottom>Pay <b>{paymentPayload?.currency} {paymentPayload?.amount / 100}</b> to request ECG report for interpretation.</Typography>
              <Typography variant="body2">You have exceeded your <b>free</b> ECG report interpretation quota for today.</Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{ paddingRight: 2, paddingBottom: 2 }}>
            <Button sx={{ marginRight: 2 }} variant='outlined' color="error" onClick={handlePaymentPopupClose}>
              Cancel
            </Button>
            <Button variant='contained' color='primary' onClick={handlePaymentPopup} payload={paymentPayload} autoFocus>
              Proceed
            </Button>
          </DialogActions>
        </Dialog>
        <Card>
          <Box>
            <UserListToolbar
              filterName={filterName}
              onFilterName={handleFilterByName}
              newStatus={handleDatastatus}
              startDate={startDate}
              endDate={endDate}
              handleStartDateChange={handleStartDateChange}
              selectedIds={!!account?.settings?.can_request_for_review ? selected : null}
              completeSelectedIds={!!account?.settings?.can_download_report ? completeSelected : null}
              requestedSelectedReportClick={handleRowButtonClick}
              downloadSelectedReportClick={downloadPDF}
              unselecteReports={cancelAllSelected}
              filterReportTypeData={changeFilter}
              ButtonLoading={ButtonLoadingStatus}
              DownloadButtonLoading={downloadButtonLoadingStatus}
              isSmall={isSmall}
              filteredDataCount={!loading ? { total: dataCount?.totalTests, completed: dataCount?.totalCompleted || 0, requested: dataCount?.totalRequested || 0, toRequest: (!!account?.settings?.can_request_for_review ? dataCount?.totalToRequest || 0 : null) } : null}
              isClinicReports={ !!getMyClinicsPhoneNumber }
            />
            <Box>
              {loading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '30px', alignItems: 'center' }}>
                  <CircularProgress />
                </Box>
              ) : (
                <Scrollbar sx={{ height: (getMyClinicsPhoneNumber ? '56vh' : '100%') }}>
                  {/* <TableContainer sx={{ minWidth: 800 }}> */}
                  <Table>
                    <UserListHead
                      order={order}
                      orderBy={orderBy}
                      headLabel={TABLE_HEAD}
                      rowCount={data.length}
                      numSelected={selected?.length + completeSelected?.length}
                      onRequestSort={handleRequestSort}
                      onSelectAllClick={handleSelectAllClick}
                      hideCheckbox={!account?.business_id}
                    />
                    {data.length ? (
                      <TableBody>
                        {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                          const { id, user_Data, report_timestamp, interpretation_request_status } = row;
                          const selectedUser = selected.indexOf(name) !== -1;
                          return (
                            <TableRow hover key={index} tabIndex={-1} role="checkbox" selected={selectedUser}>
                              <TableCell padding="checkbox">
                                {(account?.business_id && (!!account?.settings?.can_request_for_review || !!account?.settings?.can_download_report)) && <Checkbox
                                  disabled={!(!row.interpretation_request_status || (row.interpretation_request_status === "completed"))}
                                  checked={selected.includes(row?.id) || completeSelected.includes(row?.id)}
                                  onChange={(event) => handleClick(event.target.checked, row)}
                                />}
                              </TableCell>
                              <TableCell component="th" scope="row" padding="none">
                                <Stack direction="row" alignItems="center" spacing={2}>
                                  <ContentCopy fontSize='small' sx={{ cursor: "pointer" }} onClick={onCopyClick(row?.user_data?.phone_number)} />
                                  <Tooltip title={row?.user_data?.phone_number}>
                                    {/* <Avatar alt={row?.user_Data?.first_name} {...row?.user_data?.last_name} /> */}
                                    <OverflowTypography variant="subtitle2" noWrap>
                                      {row?.name}
                                    </OverflowTypography>
                                  </Tooltip>
                                </Stack>
                              </TableCell>
                              <TableCell>
                                <OverflowTypography maxWidth={150} variant="subtitle2" noWrap>
                                  {row?.report_type?.replace(/REPORT|_/g, ' ')}
                                </OverflowTypography>
                              </TableCell>
                              <TableCell>
                                <Tooltip
                                  title={
                                    row?.report_timestamp
                                      ? dayjs(parseInt(row?.report_timestamp)).format('DD MMM, YYYY hh:mm A')
                                      : '--'
                                  }
                                >
                                  <span>
                                    {row?.report_timestamp
                                      ? dayjs(parseInt(row?.report_timestamp)).format('DD MMM hh:mm A')
                                      : '--'}
                                  </span>
                                </Tooltip>
                              </TableCell>
                              {!getMyClinicsPhoneNumber && (
                                <TableCell>
                                  <Tooltip title={(row?.interpretation_completed_at ? dayjs(parseInt(row?.interpretation_completed_at)).format('DD MMM, YYYY hh:mm A') : (row?.interpretation_requested_at ? dayjs(parseInt(row?.interpretation_requested_at)).format('DD MMM, YYYY hh:mm A') : '--'))}>
                                    <span>{(row?.interpretation_completed_at ? dayjs(parseInt(row?.interpretation_completed_at)).format('DD MMM hh:mm A') : (row?.interpretation_requested_at ? dayjs(parseInt(row?.interpretation_requested_at)).format('DD MMM hh:mm A') : '--'))}</span>
                                  </Tooltip>
                                </TableCell>
                              )}
                              <TableCell>
                                {getMyClinicsPhoneNumber ? (
                                  row?.conclusions?.ecg_type || 'normal'
                                ) : status === "completed" || !status ? (
                                  row?.interpretation_report_status ? (
                                    <Typography
                                      color={
                                        row?.interpretation_report_status === 'abnormal'
                                          ? 'warning.main'
                                          : row?.interpretation_report_status === 'critical'
                                            ? 'error.main'
                                            : row?.interpretation_report_status === 'retake'
                                              ? 'info.main'
                                              : 'success.main'
                                      }
                                      sx={{ textTransform: 'capitalize' }}
                                    >
                                      {row?.interpretation_report_status || 'normal'}
                                    </Typography>
                                  ) : (
                                    '--'
                                  )
                                ) : null}
                              </TableCell>

                              {!getMyClinicsPhoneNumber && (
                                <>
                                  <TableCell>
                                    <span key={`status_label_${id}`}>
                                      {row?.interpretation_request_status ?
                                        <Label
                                          color={
                                            row.interpretation_request_status === 'requested'
                                              ? 'primary'
                                              : row.interpretation_request_status === 'completed'
                                                ? 'success'
                                                : row.interpretation_request_status === 'in progress'
                                                  ? 'warning'
                                                  : 'default'
                                          }
                                        >
                                          {row?.interpretation_request_status}
                                        </Label> : '--'}
                                    </span>
                                  </TableCell>

                                  <TableCell align="center">
                                    {!!account?.settings?.can_download_report && row?.interpretation_request_status === 'completed' && dayjs(row?.interpretation_completed_at).isBefore(dayjs().subtract(5, 'minute')) ? (
                                      <>
                                        <Tooltip title="Download" arrow>
                                          <LoadingButton
                                            loading={rowDownloadLoading?.some(reportIds => reportIds === row?.id) || downloadButtonLoadingStatus}
                                            variant="contained"
                                            disabled={
                                              completeSelected.some((reportId) => reportId !== row?.id) ||
                                              rowDownloadLoading?.some((reportId) => reportId !== row?.id)
                                            }
                                            onClick={(event) => downloadPDF(event, [row?.id])}
                                          >
                                            <Download />
                                          </LoadingButton>
                                        </Tooltip>

                                        {/* Optional: Share Button */}
                                        {/* 
          <Button aria-describedby="adadsa" variant="contained">
            <Share />
          </Button> 
          */}
                                      </>
                                    ) : (
                                      <>
                                        {/* Show "Request" button if certain conditions are met */}
                                        {!row?.interpretation_request_status && !!account?.settings?.can_request_for_review ? (
                                          <LoadingButton
                                            key={`request_btn_${row?.id}`}
                                            loading={
                                              rowLoading?.some((reportId) => reportId === row?.id) || ButtonLoadingStatus
                                            }
                                            variant="contained"
                                            disabled={
                                              selected.includes(row?.id) ||
                                              rowLoading?.some((reportId) => reportId !== row?.id)
                                            }
                                            onClick={(event) =>
                                              handleRowButtonClick(event, [row.id], row, row?.interpretation_request_status)
                                            }
                                          >
                                            Request
                                          </LoadingButton>

                                        ) : null}
                                      </>
                                    )}
                                  </TableCell>
                                </>
                              )}
                            </TableRow>
                          );
                        })}
                        {emptyRows > 0 && (
                          <TableRow style={{ height: 53 * emptyRows }}>
                            <TableCell colSpan={6} />
                          </TableRow>
                        )}
                        {isNotFound && (
                          <TableRow>
                            <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                              <Paper
                                sx={{
                                  textAlign: 'center',
                                }}
                              >
                                <Typography variant="h6" paragraph>
                                  Not found
                                </Typography>
                                <Typography variant="body2">
                                  No results found for &nbsp;
                                  <strong>&quot;{filterName}&quot;</strong>.
                                  <br /> Try checking for typos or using complete words.
                                </Typography>
                              </Paper>
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    ) : (
                      <TableFooter>
                        <TableRow>
                          <TableCell style={{ width: '100%' }} align="center" colSpan={6} v>
                            <Typography mt={2}>No interpretation request found.</Typography>
                          </TableCell>
                        </TableRow>
                      </TableFooter>
                    )}
                  </Table>
                  {/* </TableContainer> */}
                </Scrollbar>
              )}
            </Box>
          </Box>
          {data.length ? (
            <TablePagination
              rowsPerPageOptions={[5, 10, 20, 25]}
              component="div"
              count={data?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          ) : null}
        </Card>
      </Container >
      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          Edit
        </MenuItem>

        <MenuItem sx={{ color: 'error.main' }}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Delete
        </MenuItem>
      </Popover>
    </>
  );
}
