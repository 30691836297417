import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import { DataGrid, GridToolbarContainer, GridToolbarExport, GridToolbarQuickFilter, GridToolbarColumnsButton, GridToolbarFilterButton } from '@mui/x-data-grid';


// @mui
import {
  Card,
  Box,
  Table,
  Stack,
  Chip,
  TableHead,
  TextField,
  TableRow,
  TableBody,
  TableCell,
  ButtonGroup,
  TableContainer,
  Container,
  Typography,
  TableFooter,
  Tooltip,
  Link,
  Button,
  Divider,
  List,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  ListItem,
  ListItemText,
  ListItemIcon,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Grid,
  Paper


} from '@mui/material';
import useResponsive from '../hooks/useResponsive';

// components
import { styled } from '@mui/system';
import CircularProgress from '@mui/material/CircularProgress';
import { getAllReports, downloadLeadFile, careLogAiInterpretation } from 'api/reports';
import { useParams } from "react-router-dom";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

export default function RDReports() {
  const isLg = useResponsive('up', 'md');
  const { id } = useParams();
  const [time, setTime] = useState('Recent');
  const [status, setStatus] = useState('');
  const [data, setData] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [openAiDialog, setOpenAiDialog] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [filteredData, setFilteredData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [reportType, setReportType] = useState('')
  const [datePicker, setDatePicker] = useState(null);
  const [aiResponseData, setAiResponseData] = useState(null);
  const [analyticsLoading, setAnalyticsLoading] = useState({});

  const Item = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(2),
    textAlign: 'right',
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.background.default,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[1],
  }));

  const leads = {
    lead1_data: 'Lead I data',
    lead2_data: 'Lead II data',
    lead3_data: 'Lead III data',
    avf_data: 'AVF data',
    avl_data: 'AVL data',
    avr_data: 'AVR data',
    v1_data: 'V1 data',
    v2_data: 'V2 data',
    v3_data: 'V3 data',
    v4_data: 'V4 data',
    v5_data: 'V5 data',
    v6_data: 'V6 data',
    five_minute_data: 'Lead II data'
  };
  const columns = [
    { field: 'first_name', headerName: 'Name', width: 150, valueGetter: (params) => params.row.user_data?.first_name || 'N/A' },
    { field: 'phone_number', headerName: 'Phone Number', width: 180 },
    { field: 'report_type', headerName: 'Report Type', width: 180 },
    { field: 'report_timestamp', headerName: 'Taken At', width: 150, valueFormatter: (params) => { const timestamp = Number(params?.value); return dayjs(timestamp).isValid() ? dayjs(timestamp).format('DD/MM/YYYY') : 'Invalid Date'; } },
    { field: 'status', headerName: 'Status', width: 150, valueGetter: (params) => getReportStatus(params.row) },
    {
      field: 'action',
      headerName: 'Actions',
      width: 150,
      renderCell: (params) => (
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={() => handleActionButton(params?.row)}
        >
          Lead Data
        </Button>
      ),
    }
    , {
      field: 'ai_analytics',
      headerName: 'AI Analytics',
      width: 180,
      renderCell: (params) => (
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={() => handleAiAnalyticsButton(params.row)}
          disabled={analyticsLoading[params.row.id] === true}
        >
          {analyticsLoading[params.row.id] ? <CircularProgress size={24} /> : "Ecg Analysis"}
        </Button>
      )
    }

  ];



  const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
    '& .MuiDataGrid-columnHeaders': {
      backgroundColor: '#F4F6F8',
      color: '#637381',
      fontWeight: 'bold',
    },
    '& .MuiDataGrid-cell': {
      borderBottom: 'none',
    },
    '& .MuiDataGrid-row': {
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.05)',
      },
    },
  }));
  useEffect(() => {
    fetchReports();
  }, [time, id, datePicker])


  const formatDate = (date) => {
    return date ? dayjs(date).format('DD MMM, YYYY') : null;
  }; 
  
  useEffect(() => {
    filterData()
  }, [data, status, reportType, searchTerm, datePicker])

  const filterData = () => {
    let filtered = data;

    if (status && status !== 'None') {
      filtered = filtered.filter((report) => {
        if ((report.report_type == 'REPORT_TWELVE_LEAD' || report.report_type == 'REPORT_LEAD_TWO' || report.report_type == 'REPORT_SEVEN_LEAD') && report.conclusions.ecg_type.includes(status)) {
          return true;
        } else if (report.report_type === 'HRV' && report.conclusions.hrv_test_analysis.includes(status)) {
          return true;
        } else if (report.report_type === 'REPORT_HYPERKALEMIA' && report.conclusions.arrhythmiaEvaluation.includes(status)) {
          return true;
        } else {
          return false;
        }
      });
    }

    if (reportType && reportType !== 'All') {
      filtered = filtered.filter((report) => report.report_type === reportType);
    }

    if (searchTerm) {
      filtered = filtered.filter((user) => {
        return (
          user?.user_data?.first_name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          user?.user_data?.phone_number?.includes(searchTerm)
        );
      });
    }

    setFilteredData(filtered);
  }

  //to fetch all the reports based on time . by default the time is set to recent 
  const fetchReports = async () => {
    setLoading(true)
    let response;
    if (datePicker) {

      response = await getAllReports({
        business_id: id,
        startDate: formatDate(datePicker),
        endDate: formatDate(datePicker),
        role: 'r&dteam'

      });
    } else {
      response = await getAllReports({
        business_id: id,
        time: time,
        role: 'r&dteam'

      });
    }


    setLoading(false);
    setData(response.data)

  }

  const handleTime = async (e) => {
    setDatePicker(null);
    setTime(e);
  }

  const handleStatus = async (e) => {
    const status = e.target.value
    setStatus(status)

  }

  const handleActionButton = async (data) => {
    console.log(data, "data");
    setSelectedData(data);
    setOpenDialog(true);
  }

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setOpenAiDialog(false)
  }

  const getReportStatus = (d) => {
    if (d.report_type == 'REPORT_TWELVE_LEAD' || d.report_type == 'REPORT_LEAD_TWO' || d.report_type == 'REPORT_SEVEN_LEAD') {
      return d.conclusions.ecg_type

    }
    else if (d.report_type === 'HRV') {
      return d.conclusions.hrv_test_analysis
    }
    else if (d.report_type === 'REPORT_HYPERKALEMIA') {
      return d.conclusions.arrhythmiaEvaluation
    }
  }

  const handleDownloadButton = async (path, reportType) => {

    try {
      const response = await downloadLeadFile({
        path: [path],
        report_type: reportType,
      });

      response.urls.forEach(url => {

        const link = document.createElement('a');
        link.href = url;
        link.target = '_blank';
        link.download = '';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
    }
    catch (e) {
      console.log(e)
    }

  }

  const handleDownloadAll = async () => {
    if (!selectedData || !selectedData.paths) return;

    const paths = Object.keys(selectedData.paths).map(key => selectedData.paths[key]);
    const response = await downloadLeadFile({
      path: paths,
      report_type: selectedData?.report_type,

    });

    response.urls.forEach(url => {

      const link = document.createElement('a');
      link.href = url;
      link.target = '_blank';
      link.download = '';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

    })
  };


  const handleReportType = (e) => {

    setReportType(e.target.value)
  }

  const handleSearch = (event) => {
    const { value } = event.target;
    setSearchTerm(value);
  };

  const handleDateChange = (date) => {

    setTime(null)
    setDatePicker(date)

  }

  const handleAiAnalyticsButton = async (data) => {

    if (!data || !data.paths) return;
    const paths = Object.keys(data.paths).map(key => data.paths[key]);
    setAnalyticsLoading((prev) => ({ ...prev, [data.id]: true }));

    try {

      const response = await careLogAiInterpretation({
        path: paths,
        report_type: data?.report_type,

      });
      console.log("response", response)
      setAiResponseData(response)
      setOpenAiDialog(true)
    }

    catch (err) {
      console.log("Err Occurred", err)

    }
    finally {
      setAnalyticsLoading((prev) => ({ ...prev, [data.id]: false }));
    }
  }
  function CustomToolbar() {
    const handleExport = () => {
      if (filteredData.length === 0) return;

      const rowsToExport = filteredData.map((row) => {

        console.log("row", row)
        return [
          row?.user_data?.first_name || '',
          row?.phone_number || '',
          row?.report_type || '',
          dayjs(row.report_timestamp).format('DD/MM/YYYY') || '',
          getReportStatus(row) || '',
        ];
      });

      const csvContent = rowsToExport
        .map((row) => row.join(','))
        .join('\n');

      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', 'ECG_Interpretation_Service_Portal_Sunfox.csv');
      link.click();
    };

    return (
      <GridToolbarContainer sx={{ justifyContent: 'space-between', gap: 1, padding: '0 20px' }}>
        <GridToolbarQuickFilter />
        <Stack direction="row" spacing={3} justifyContent="space-between">
          <GridToolbarColumnsButton />
          <GridToolbarFilterButton />
          <Button onClick={handleExport}>Export</Button>
        </Stack>
      </GridToolbarContainer>
    );
  }

  return (
    <>
      <Container sx={{ marginTop: isLg ? 0 : 1 }}>
        <Stack direction="column" mb={2}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
            <ButtonGroup variant="outlined" size="large" aria-label="Large button group">
              <Button color="primary" variant={time === 'Recent' ? 'contained' : 'outlined'} onClick={() => handleTime('Recent')}>
                Recent
              </Button>
              <Button color="success" variant={time === 'Weekly' ? 'contained' : 'outlined'} onClick={() => handleTime('Weekly')}>
                Weekly
              </Button>
              <Button color="warning" variant={time === 'Monthly' ? 'contained' : 'outlined'} onClick={() => handleTime('Monthly')}>
                Monthly
              </Button>
            </ButtonGroup>
            <Stack direction="row" alignItems="center" spacing={2}>
              <FormControl sx={{ m: 2, minWidth: 200 }} size="medium">
                <InputLabel id="demo-simple-select-label">Report Type</InputLabel>
                <Select labelId="demo-simple-select-label" id="demo-simple-select" variant="standard" value={reportType} label="report type" onChange={handleStatus}>
                  <MenuItem value={'REPORT_TWELVE_LEAD'}>Twelve Lead Report</MenuItem>
                  <MenuItem value={'REPORT_LEAD_TWO'}>Lead Two Report</MenuItem>
                  <MenuItem value={'REPORT_SEVEN_LEAD'}>Seven Lead Report</MenuItem>
                  <MenuItem value={'HRV'}>HRV</MenuItem>
                  <MenuItem value={'REPORT_HYPERKALEMIA'}>Hyperkalemia Report</MenuItem>
                  <MenuItem value={'All'}>All Reports</MenuItem>
                </Select>
              </FormControl>
              <FormControl sx={{ m: 2, minWidth: 200 }} size="medium">
                <InputLabel id="demo-simple-select-label">Report Status</InputLabel>
                <Select labelId="demo-simple-select-label" id="demo-simple-select" value={status} variant="standard" label="report status" onChange={handleStatus}>
                  <MenuItem value={'Normal'}>Normal ECG</MenuItem>
                  <MenuItem value={'Abnormal'}>Abnormal ECG</MenuItem>
                  <MenuItem value={'Critical'}>Critical</MenuItem>
                  <MenuItem value={'None'}>None</MenuItem>
                </Select>
              </FormControl>
              <DatePicker sx={{ width: '160px' }} label="Select Date" value={datePicker} onChange={handleDateChange} />
            </Stack>
          </Stack>
          <Stack>
            <TextField id="search-bar" label="Search by name or number" variant="standard" value={searchTerm} onChange={handleSearch} size="small" sx={{ width: '250px' }} />
          </Stack>
        </Stack>

        <Box sx={{ height: '68vh', width: '100%' }}>
          <DataGrid
            rows={filteredData}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5]}
            loading={loading}
            disableSelectionOnClick
            components={{ Toolbar: CustomToolbar }}
            checkboxSelection
          />
        </Box>
      </Container>
      <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="md" fullWidth sx={{ '& .MuiDialog-paper': { height: '50vh' } }}>
        <DialogTitle>Download Lead Files</DialogTitle>
        <Box align="right" sx={{ position: 'absolute', top: '10px', right: '0' }}>
          <Button
            variant="contained"
            color="secondary"
            startIcon={<DownloadForOfflineIcon />}
            onClick={handleDownloadAll}
            sx={{ marginLeft: 2, marginRight: 2, padding: '8px 16px', fontSize: '14px' }}
          >
            Download All
          </Button>
        </Box>
        <DialogContent>
          {selectedData && (
            <Box sx={{ width: '100%' }}>
              <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                {Object.keys(leads).map((key) => {
                  const leadLabel = leads[key];
                  const leadData = selectedData?.paths?.[key];
                  if (!leadData) {
                    return null;
                  }

                  return (
                    <Grid item xs={6} key={key}>
                      <Item>
                        <Typography variant="subtitle2" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                          <strong>{leadLabel}</strong>
                          <Box>
                            <Button
                              variant="contained"
                              color="primary"
                              target="_blank"
                              startIcon={<DownloadForOfflineIcon />}
                              download
                              sx={{ marginLeft: 2 }}
                              onClick={() => handleDownloadButton(leadData, selectedData?.report_type)}
                            >
                              Download
                            </Button>
                          </Box>
                        </Typography>
                      </Item>
                    </Grid>


                  );
                })}
              </Grid>
            </Box>
          )

          }

        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Close</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openAiDialog} onClose={handleCloseDialog} maxWidth="sm" fullWidth sx={{ '& .MuiDialog-paper': { height: '50vh' } }}>
        <DialogTitle>Ai Interpretation</DialogTitle>

        <DialogContent>
          {aiResponseData &&(
              <div>
              <h3>Interpretation:</h3>
              {aiResponseData?.interpretation && Object.keys(aiResponseData.interpretation).length === 0 && (
        <p>{aiResponseData.message}</p>
      )}
             
              
              {aiResponseData?.interpretation && Object.keys(aiResponseData.interpretation).length > 0 && (
                <div>
                  <p><strong>Cardiac Enlargement:</strong> {aiResponseData?.interpretation?.cardiac_enlargment?.label}</p>
                  <p><strong>Ejection Fraction:</strong> {aiResponseData?.interpretation?.ejection_fraction?.label}</p>
                </div>
              )}
            </div>        
          )
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Close</Button>
        </DialogActions>
      </Dialog>

    </>
  );
}



