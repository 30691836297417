import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import AllTests from './AllTests';

// @mui components
import {
  Card,
  Box,
  Container,
  Typography,
  CircularProgress,
  Stack,
  Avatar,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
} from '@mui/material';
import { styled } from '@mui/system';
import { DataGrid, GridToolbarContainer, GridToolbarQuickFilter } from '@mui/x-data-grid';
import { useSnackbar } from '../components/snackbar';
import useResponsive from '../hooks/useResponsive';
import { getMyClinics } from 'api/users';
import RDReports from './RDReports';

// Styled DataGrid
const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  '& .MuiDataGrid-columnHeaders': {
    backgroundColor: '#F4F6F8',
    color: '#637381',
    fontWeight: 'bold',
  },
  '& .MuiDataGrid-cell': {
    borderBottom: 'none',
  },
  '& .MuiDataGrid-row': {
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.05)',
    },
  },
}));

function CustomToolbar() {
  return (
    <GridToolbarContainer sx={{ justifyContent: 'space-between', padding: '0 20px' }}>
      <GridToolbarQuickFilter />
    </GridToolbarContainer>
  );
}

export default function MyClinics() {
  const { enqueueSnackbar } = useSnackbar();
  const isLg = useResponsive('up', 'md');
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedPhoneNumber, setSelectedPhoneNumber] = useState(null);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await getMyClinics();
      setLoading(false);
      if (response?.success) {
        setData(
          response.data.map((clinic, index) => ({
            id: index,
            clinicName: clinic.clinicName,
            phoneNumber: clinic.phoneNumber,
            address: clinic.clinicAddress,
            clinicLogo: clinic.clinicLogo,
          }))
        );
      } else {
        enqueueSnackbar(response?.message, { variant: 'error' });
      }
    } catch (e) {
      console.error(e);
      enqueueSnackbar('Unable to fetch clinics.', { variant: 'error' });
    }
  };

  const handleDialogOpen = (phoneNumber) => {
    setSelectedPhoneNumber(phoneNumber);
  };

  const handleDialogClose = () => {
    setSelectedPhoneNumber(null);
  };

  const TABLE_COLUMNS = [
    {
      field: 'clinicName',
      headerName: 'Clinic Name',
      minWidth: 200,
      flex: 1,
      renderCell: (params) => (
        <Stack direction="row" alignItems="center" spacing={2}>
          <Avatar alt={params.row.clinicName} src={params.row.clinicLogo} />
          <Typography variant="subtitle2" noWrap>
            {params.row.clinicName}
          </Typography>
        </Stack>
      ),
    },
    { field: 'phoneNumber', headerName: 'Phone No.', minWidth: 150, flex: 1 },
    { field: 'address', headerName: 'Address', minWidth: 250, flex: 1 },
    {
      field: 'action',
      headerName: 'Action',
      minWidth: 250,
      flex: 1,
      renderCell: (params) => (
        <Stack direction="row" alignItems="center" spacing={2}>
          <Button
            variant="contained"
            size="small"
            onClick={() => handleDialogOpen(params.row.phoneNumber)}
          >
            View Details
          </Button>
        </Stack>
      ),
    },
  ];

  return (
    <>
      <Helmet>
        <title>My Clinics | Sunfox Experts</title>
      </Helmet>

      <Container sx={{ marginTop: isLg ? 0 : 1 }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
          <Typography variant="h4" gutterBottom>
            My Clinics
          </Typography>
        </Stack>
        <Card>
          {loading ? (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '300px',
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <Box sx={{ height: '75vh', width: '100%' }}>
              <StyledDataGrid
                rows={data}
                columns={TABLE_COLUMNS}
                pageSize={10}
                localeText={{ noRowsLabel: 'No clinics found.' }}
                initialState={{ pagination: { paginationModel: { pageSize: 10 } } }}
                pageSizeOptions={[5, 10, 20]}
              />
            </Box>
          )}
        </Card>
      </Container>

      {selectedPhoneNumber && (
        <Dialog open={!!selectedPhoneNumber} onClose={handleDialogClose} maxWidth="lg" fullWidth sx={{ minHeight: '70vh' }} style={{ minHeight: '70vh !important' }}>
          <DialogContent sx={{ minHeight: '80vh' }}>
            {/* <RDReports clinicId={selectedPhoneNumber} role='user' /> */}
            <AllTests getMyClinicsPhoneNumber={selectedPhoneNumber} />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}
