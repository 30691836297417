import SvgColor from '../../../components/svg-color';
import { AccountBox, ManageAccounts, GroupAdd, People, NoteAlt, History, Description, CurrencyRupee, MonitorHeart, Insights, BusinessCenter, Business } from '@mui/icons-material';

const icon = (name) => <SvgColor src={`${process.env.REACT_APP_HOMEPAGE}assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const generateNavConfig = (account) => {
  let navConfig = [];
  const userType = account?.userType;
  switch (userType) {
    case 'user':
      navConfig = [
        {
          title: 'dashboard',
          path: `userDashboard`,
          icon: icon('ic_analytics'),
        },
        {
          title: 'Tests',
          path: `allTests`,
          icon: icon('ic_stethoscope'),
        },
        {
          title: 'Paid Requests',
          path: 'userOrder',
          icon: <History />,
        },
      ];
      break;
    case 'marketingteam':
      navConfig = [
        // {
        //   title: 'dashboard',
        //   path: `userDashboard`,
        //   icon: icon('ic_analytics'),
        // },
        {
          title: 'Onboarded Users',
          path: `marketingteamDashboard`,
          icon: <People/>,
        },
        {
          title: 'Guest Users',
          path: 'app-guest-user',
          icon: <People/>,
        },
        {
          title: 'Profile',
          path: 'profile',
          icon: <AccountBox />,
        }
      ];
      break;
      case 'managementteam':
      navConfig = [
        {
          title: 'Subscription Plans',
          path: 'managementteamDashboard',
          icon: <AccountBox />,
        },
        {
          title: 'Business Clients',
          path: `business-clients`,
          icon: <GroupAdd/>,
        }, 
        {
          title: 'My Clinics',
          path: `my-clinics`,
          icon: <ManageAccounts />,
        },
        {
          title: 'Plan Users ',
          path: `plan-users`,
          icon: <People/>,
        },
        
        
        
      ];
      break;
      case 'r&dteam':
      navConfig = [
        {
          title: "Business",
          path: 'r&dteamDashboard',
          icon: <Business/>,
        },
        {
          title: "Clinic",
          path: 'clinics',
          icon: <BusinessCenter />,
        },
        {
          title: "ECG Tests",
          path: `reports`,
          icon: icon('ic_stethoscope'),
        }, 
        // {
        //   title: 'My Clinics',
        //   path: `MyCLinics`,
        //   icon: <ManageAccounts />,
        // },
        // {
        //   title: 'Plan Users ',
        //   path: `PlanUsers`,
        //   icon: <People/>,
        // },
        
      ];
      break;

    case 'expert':
      navConfig = [
        {
          title: 'dashboard',
          path: `expertDashboard`,
          icon: icon('ic_analytics'),
        },
        {
          title: 'Reports',
          path: `allReports`,
          icon: <Description />,
        },
        {
          title: 'Interpretation Utility',
          path: `interpretationUtility`,
          icon: <NoteAlt />,
        },
        {
          title: 'Profile',
          path: 'profile',
          icon: <AccountBox />,
        }
      ];
      break;
    case 'b2b':
      navConfig = [
        {
          title: 'dashboard',
          path: `b2bDashboard`,
          icon: icon('ic_analytics'),
        },
        {
          title: 'Tests',
          path: `allTests`,
          icon: icon('ic_stethoscope'),
        },
        {
          title: 'Profile',
          path: 'profile',
          icon: <AccountBox />,
        },
        {
          title: 'Manage System Users',
          path: 'business-users',
          icon: <ManageAccounts />,
        },
        {
          title: 'Manage Associated Users',
          path: 'associated-users',
          icon: <ManageAccounts />,
        }, 
         {
          title: 'Test by Associated Users',
          path: 'associate-users-count',
          icon: <ManageAccounts />,
        },
        {
          title: 'Procured Devices',
          path: 'procured-devices',
          icon: icon('ic_device'),
        },
        {
          title: 'Device Usage',
          path: 'deviceusage',
          icon: <MonitorHeart />
        },
      ];
      break;
      case 'b2b-user':
        navConfig = [
          {
            title: 'dashboard',
            path: `b2b-userDashboard`,
            icon: icon('ic_analytics'),
          },
          ...(account?.settings?.can_view_data ? [{
            title: 'Tests',
            path: `allTests`,
            icon: icon('ic_stethoscope'),
          }] : []),
          ...(account?.settings?.can_manage_business_admin ? [{
            title: 'Manage System Users',
            path: 'business-users',
            icon: <ManageAccounts />,
          }] : []),
          ...(account?.settings?.can_manage_associated_user ? [{
            title: 'Manage Associated Users',
            path: 'associated-users',
            icon: <ManageAccounts />,
          }] : []),
          {
            title: 'Profile',
            path: 'profile',
            icon: <AccountBox />,
          },
        ];
        break;
    case 'admin':
      navConfig = [
        {
          title: 'dashboard',
          path: `adminDashboard`,
          icon: icon('ic_analytics'),
        },
        {
          title: 'Reports',
          path: `allReports`,
          icon: <Description />,
        },
        {
          title: 'Find Reports',
          path: `find-reports`,
          icon: <Description />,
        },

        {
          title: 'ECG Analysis',
          path: `ecg-analysis`,
          icon: <Insights/>,
        },
        {
          title: 'Profile',
          path: 'profile',
          icon: <AccountBox />,
        },
        {
          title: 'Manage User',
          path: 'internal-users',
          icon: <ManageAccounts />,
        },
        {
          title: 'Manage Client',
          path: 'clients',
          icon: <GroupAdd />,
        },
        {
          title: 'View B2B Dashboard',
          path: 'b2b-dashboard',
          icon: icon('ic_analytics'),
        },
        {
          title: 'Onborded Users',
          path: 'app-onboarded-user',
          icon: <People/>,
        },
        {
          title: 'Guest Users',
          path: 'app-guest-user',
          icon: <People/>,
        },
         {
          title: 'Subscription Plans',
          path: 'subscription-plans',
          icon: <Description />,
        },
        {
          title: 'Plan Users',
          path: 'plan-users',
          icon: <People/>,
        },
        {
          title: 'Extend Plan Users',
          path: `extend-plan-users`,
          icon: <People/>,
        },
        {
          title: 'My Clinics',
          path: 'my-clinics',
          icon: <ManageAccounts />,
        },
        {
          title: 'Business Clients',
          path: `business-clients`,
          icon: <GroupAdd/>,
        }
      ];
      break;
      case 'superuser':
        navConfig = [
          {
            title: 'dashboard',
            path: `adminDashboard`,
            icon: icon('ic_analytics'),
          },
          {
            title: 'Reports',
            path: `allReports`,
            icon: <Description />,
          },
          {
            title: 'ECG Analysis',
            path: `ecg-analysis`,
            icon: <Insights/>,
          },
          {
            title: 'Profile',
            path: 'profile',
            icon: <AccountBox />,
          },
          {
            title: 'Manage User',
            path: 'internal-users',
            icon: <ManageAccounts />,
          },
          {
            title: 'Manage Client',
            path: 'clients',
            icon: <GroupAdd />,
          },
          {
            title: 'finance',
            path: 'finance',
            icon: <CurrencyRupee />,
          },
          {
            title: 'View B2B Dashboard',
            path: 'b2b-dashboard',
            icon: icon('ic_analytics'),
          },
          {
            title: 'Onborded Users',
            path: 'app-onboarded-user',
            icon: <People/>,
          },
          {
            title: 'Guest Users',
            path: 'app-guest-user',
            icon: <People/>,
          },
          {
            title: 'Subscription Plans',
            path: 'subscription-plans',
            icon: <Description />,
          },
          {
            title: 'Plan Users',
            path: 'plan-users',
            icon: <People/>,
          },
          {
            title: 'My Clinics',
            path: 'my-clinics',
            icon: <ManageAccounts />,
          },
          {
            title: 'Business Clients',
            path: `business-clients`,
            icon: <GroupAdd/>,
          }
          
        ];
        break;
      default:
        break;
  }

  return navConfig;
};

export default generateNavConfig;