import axios from 'axios';
import { BASE_URL } from '../client-config'

const axiosInstance = axios.create({ baseURL: BASE_URL });
axiosInstance.interceptors.response.use(
  (res) => res,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong'+error)
);
export default axiosInstance;

export const setSession = (authToken) => {
  try {
    axiosInstance.defaults.headers = null;
    if (authToken) {
      axiosInstance.defaults.headers = { authorization: encodeURI(authToken) };
    }
  } catch(e){
    console.log(e);
  }

}

export const endpoints = {
  auth: {
    generateOTP: '/generateotp',
    verifyOTP: '/verifyotp',
    login: '/api/auth/login',
  },
  users: "/getusers",
  dashboard:'/analytics/get-interpretation-analytics',
  trends: '/analytics/get-annual-analytics',
  groupChart: '/analytics/get-total-completed-group-by-expert-and-report-types',
  reports: {
    list: '/ecg/get-list',
    details: '/api/report/details',
    latest: '/api/report/latest',
    search: '/api/report/search',
  },
  loginProcess:{
    usersLogin:'/user/login',
    otpVerify:'/user/verify',
    verifyToken: '/user/verify-token',
    tester:'/user/tester',
    generateFcmToken:'user/save-push-token',
    deleteFcmToken:'user/logout'
  },
  interpretation: {
    requestInterpretation: '/ecg/request-for-interpretation',
    revokeInterpretationRequest: '/ecg/revoke-interpretation-request',
    saveDataInterpretation: '/ecg/save-interpretation-data',
    careLogAiInterpretation: '/ecg/careLog-ai-interpretation',
  },
  data: {
    getEcgData: '/ecg/get-ecg-data/',
    getEcgReportAndData: '/ecg/download-ecg-report/',
    downloadLeadFile:'/ecg/downloadLeadFile'
  },
  testdata: {
    getMonthlyData: '/device/device-tests',
    getDataPercentage: '/device/device-test-percentages',
    getTestCount: '/device/monthly-test-counts',
  },
  device: {
    getDevice: '/device',
    getReports: '/device/list/',
    report:'/device/download',
    disableEnable:'/device/disable-enable/'
  },
  user:{
    getSpecificInvoiceData: '/ecg/get-specific-invoice-data',
    getB2Busers: '/user/get-b2b-users',
    saveB2BProfile : '/user/save-b2b-profile',
    getB2BAssociatedUser: '/user/get-associated-users',
    addB2BAssociatedUser: '/user/add-associated-users',
    removeB2BAssociatedUser: '/user/remove-associated-users',
    getInternalUsers: '/user/get-users',
    saveInternalUsers: '/user/save-users',
    removeInternalUsers: '/user/remove-users',
    updateUserProfile: '/user/update-profile',
    getUserOrder:'user/get-order',
    getInvoicePdf:'user/invoice',
    saveInvoiceDetails:'user/save-invoice-details',
    fetchInvoiceData:'user/fetch-invoice-data',
    toCheckInvoiceNumber:'user/check-invoice-number',
    fetchClientPdf:'user/fetch-client-pdf',
    addB2BAdmin: 'user/add-b2b-admins',
    removeb2badminusers: 'user/remove-b2b-admin-users',
    getB2Badmindata:'user/get-b2b-admin-users',
    getAppOnboardedUsers:'user/get-app-onboarded-users',
    getGuestUsers:'user/get-guest-users',
    getSubscriptionPlan:'/analytics/get-spandan-subscription-plans',
    getMyClinics:'/analytics/get_all_my_clinics_from_firebase',
    getExisitingPlanUsers:'/analytics/get_all_active_spandan_subscriptions',
    getExtendExisitingPlanUsers: 'analytics/get-plans-subscription',
    downloadLeadFile:'/ecg/downloadLeadFile',
    getB2BAssociatedUserCount:'/analytics/get-all-associated-users-test',
    getExtendPlan: '/analytics/extend-plans-subscription'
  },
  profile:{
    getSpecificInvoiceData:'/ecg/get-specific-invoice-data',
  },
};

export const fetcher = async (args) => {
  // const [url, config] = Array.isArray(args) ? args : [args];
  // const pathParams = config.pathparams ? `${config.pathparams}` : '';
  // const queryParams = config.params ? `?${new URLSearchParams(config.params).toString()}` : '';
  // const URL = `${url.url}${pathParams}${queryParams}`;
  // const res = await axiosInstance.get(URL, { headers: generateAuth(config) });

  const [url, config] = Array.isArray(args) ? args : [args];
  const res = await axiosInstance.get(url, { ...config });
  return res.data;
};


export const poster = async (args) => {

  // const [req, config] = Array.isArray(args) ? args : [args];
  // const res = await axiosInstance.post(req.url, req.body, { headers: generateAuth(config) });

  const [url, config] = Array.isArray(args) ? args : [args];
  const res = await axiosInstance.post(url, { ...config });


  return res.data;
};

export const formPoster = async (args) => {
  const [url, formData, headers] = Array.isArray(args) ? args : [args];
  const res = await axiosInstance.post(url, formData, { 
    ...(headers ? { headers } : {
      headers: { 'Content-Type': 'multipart/form-data' }
    }) 
  });
  return res.data;
};

export const patcher = async (args) => {
  const [url, config] = Array.isArray(args) ? args : [args];
  const res = await axiosInstance.patch(url, { ...config });
  return res.data;
};

// function generateAuth(data) {
//   let tokenDetails = localStorage.getItem('TokenDetails');
//   if(tokenDetails){
//     tokenDetails = JSON.parse(tokenDetails);
//   }
//   return tokenDetails||{};
// }
