import React, { useState, useEffect, useMemo, useCallback, useRef, useContext } from 'react';
import dayjs from 'dayjs';
import { Download, Share, ContentCopy, TaskAltOutlined, ScheduleOutlined } from '@mui/icons-material';

import Label from 'components/label/Label';
import CircularProgress from '@mui/material/CircularProgress';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { getAllReports, getEcgData, getEcgReportAndData, downloadLeadFile } from 'api/reports';
import { copyToClipboard, downloadFromURI } from 'utils/Utils';

import LoadingButton from '@mui/lab/LoadingButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {Paper, TextField, List, ListItem, ListItemText, Dialog, DialogTitle, DialogContent, ButtonGroup, DialogActions, Box, Chip, Link, FormControl, InputLabel, Select, MenuItem, Alert, Card, Grid, Avatar, Table, Stack, Container, Typography, TableContainer, TablePagination, Tooltip, Button, TableRow, TableCell, TableHead, TableBody, TableFooter, Snackbar} from '@mui/material';
import useResponsive from '../hooks/useResponsive';
import { useSnackbar } from '../components/snackbar'
import { darken, lighten, styled } from '@mui/material/styles';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { Document, Page, pdfjs } from "react-pdf";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { useAuthDispatch } from 'components/AuthStateProvider';
import customStyle from '../customstyle.module.css';

import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';

import { useForm, Controller } from "react-hook-form";



//------------------------------------Data Grid-------------------------//

import {
    DataGrid,
    GridToolbarExport,
    GridToolbarContainer,
    GridToolbarQuickFilter,
    GridToolbarFilterButton,
    GridToolbarColumnsButton,
} from '@mui/x-data-grid';
//import { DataGrid, GridToolbar } from '@mui/x-data-grid';

//-----------------------------------------------------------------------
const OverflowTypography = styled(Typography)((maxWidth) => ({
    maxWidth: `${maxWidth}px`, // percentage also works
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
}));

export default function FindReports() {
    const documentRef = useRef(null);
    const { enqueueSnackbar } = useSnackbar();
    const { socket, getSocketValue } = useAuthDispatch()
    const isSmall = useResponsive('', 'xs');
    const isMd = useResponsive('between', 'xs', 'md');
    const isLg = useResponsive('up', 'md');
    const { account } = useAuthDispatch();
    const { searchStatus, searchStDate, searchEnDate, searchResult } = JSON.parse(window.sessionStorage.getItem("searchDetails") || '{}');
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('name');
    const [filterName, setFilterName] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [data, setData] = useState([]);
    const [rows, setRows] = useState([]);
    const [dataCount, setDataCount] = useState(null);
    const [status, setstatus] = useState(searchStatus);
    const [loading, setLoading] = useState(false);
    const [startDate, setStartDate] = useState(searchStDate ? dayjs(searchStDate) : dayjs());
    const [endDate, setEndDate] = useState(searchEnDate ? dayjs(searchEnDate) : dayjs());
    const [interpretationStatusFilter, setInterpretationStatusFilter] = useState('all');
    const [rowLoading, setRowLoading] = useState(null);
    const [socketUsed, setSocketUsed] = useState(false);

    const [selectedData, setSelectedData] = useState(null);

    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [selectedRowData, setSelectedRowData] = useState(null);

    const [selectionModel, setSelectionModel] = useState([]);
    const [sortModel, setSortModel] = useState([]);
    const [pdf, setPdf] = useState(null);
    const [pdfScale, setPdfScale] = useState(isLg || isMd ? 1 : 0.5);
    const [openViewPdfDialog, setOpenViewPdfDialog] = useState(false);
    const [rowId, setRowId] = useState(null)
    const [openLink, setopenLink] = useState(false);

    const [totalPages, setTotalPages] = useState(0);
    const [pdfData, setPdfData] = useState();


    const [findReportphoneNumber, setfindReportphoneNumber] = useState("");
    const [openECGLeadDialog, setopenECGLeadDialog] = useState(false);

    const Item = styled(Paper)(({ theme }) => ({
        padding: theme.spacing(2),
        textAlign: 'right',
        color: theme.palette.text.secondary,
        backgroundColor: theme.palette.background.default,
        borderRadius: theme.shape.borderRadius,
        boxShadow: theme.shadows[1],
    }));

    const handleZoom = (scale) => {
        setPdfScale(scale <= 1 ? (isLg ? 1 : 0.5) : scale)
    };
    const dialogStyles = {
        overflow: 'hidden', // Remove scroll bar
        padding: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center'
    };


    const leads = {
        lead1_data: 'Lead I data',
        lead2_data: 'Lead II data',
        lead3_data: 'Lead III data',
        avf_data: 'AVF data',
        avl_data: 'AVL data',
        avr_data: 'AVR data',
        v1_data: 'V1 data',
        v2_data: 'V2 data',
        v3_data: 'V3 data',
        v4_data: 'V4 data',
        v5_data: 'V5 data',
        v6_data: 'V6 data',

    };
    const TABLE_HEAD = useMemo(() => [
        {
            field: 'view', headerName: '', width: 20, align: 'left', renderCell: (params) => (
                <Tooltip title="View Details" arrow>
                    <Button
                        color="primary"
                        onClick={(event) => handleViewRowDetails(params?.row?.id, event)}
                        startIcon={<VisibilityIcon />}
                    />
                </Tooltip>)
        },
        { field: 'searchname', headerName: 'Name', width: status === 'completed' ? 180 : 250, align: 'left', renderCell: (params) => params.row.name, filterable: true },
        { field: 'searchreportType', headerName: 'Report Type', width: status === 'completed' ? 120 : 150, align: 'left', renderCell: (params) => params.row.reportType, filterable: true },
        {
            field: 'searchtakenAt', headerName: 'Taken At', width: status === 'completed' ? 120 : 130, align: 'left', renderCell: (params) => params.row?.takenAt, filterable: true, sortComparator: (v1, v2, param1, param2) => {
                return new Date(v1) - new Date(v2);
            },
        },
        {
            field: 'searchrequestedAt', headerName: 'Requested At', width: status === 'completed' ? 130 : 150, align: 'left', renderCell: (params) => params.row?.requestedAt, filterable: true, sortComparator: (v1, v2, param1, param2) => {
                return new Date(v1) - new Date(v2);
            },
        },
        ...(status === 'completed' || status === '' ? [{
            field: 'searchcompletedAt', headerName: 'Completed At', width: 130, align: 'left', renderCell: (params) => params.row?.completedAt, filterable: true, sortComparator: (v1, v2, param1, param2) => {
                return new Date(v1) - new Date(v2);
            },
        }] : []),
        ...(status === 'completed' || status === ''
            ? [{
                field: 'searchfindings',
                headerName: 'Findings',
                width: 100,
                align: 'left',
                renderCell: (params) => {
                    const value = params?.row?.searchfindings || '--';
                    return typeof value === 'string'
                        ? value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()
                        : value;
                },
            }]
            : []
        ),

        { field: 'searchecg_status', headerName: 'Status', width: status === 'completed' ? 160 : 200, align: 'left', renderCell: (params) => params?.row?.ecg_status },
        { field: 'action', headerName: 'Action', width: status === 'completed' ? 180 : 200, align: 'left', renderCell: (params) => params.row.action },
    ]);
    const styles = {
        documentBlock: {
            // maxWidth: 800,
            margin: "0px",
            marginTop: 5,
            // border: "1px solid #999",
            display: "flex",
            width: "100%",
            justifyContent: "center",
            height: "90vh",
            overflow: "auto",
        },
    }

    function descendingComparator(a, b, orderBy) {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }

    function getComparator(order, orderBy) {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }

    function applySortFilter(array, comparator, query) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0)
                return order;
            return a[1] - b[1];
        });
        if (query) {
            return array.filter((_user) =>
                _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
            );
        }
        return stabilizedThis.map((el) => el[0]);
    }

    const handleStartDateChange = (date) => {
        setStartDate(date);
    };

    const handleEndDateChange = (date) => {
        setEndDate(date);
    };

    const metaData = selectedRowData?.meta_data ? JSON.parse(selectedRowData.meta_data) : {};


    useEffect(() => {

        const socketVal = async () => {
            await getSocketValue();
        }

        if (socket) {

            socket.on('addReport', (result) => {
                if (result && result?.length) {
                    const filteredData = result.filter(item => !data.some(dt => item?.id == dt?.id));
                    setData(prevData => [...filteredData, ...prevData])
                    setRows(prevData => [...filteredData.map(item => { return mapRowData({ ...item, isNew: true }) }), ...prevData])
                }
            })

            socket.on('InterpretationInProgress', (resultData) => {
                if (resultData) {
                    setData((prevItems) => prevItems.map((item) => {
                        return item.id == resultData?.id ? { ...item, ...resultData } : item
                    }))
                    setRows((prevItems) => prevItems.map((item) => {
                        return item.id == resultData?.id ? mapRowData({ ...item, ...resultData, selected: true }) : item
                    }));
                }

            })
        }
        else {
            socketVal();
        }
    }, [socketUsed])

    const { control, handleSubmit } = useForm();

    // Submit function
    const onSubmit = async (data) => {
        const phoneNumber = data?.phoneNumber;
        setfindReportphoneNumber(phoneNumber);
        setLoading(true);
        await fetchData(phoneNumber);
    };

    const fetchData = async (phoneNumber) => {
        if (!phoneNumber) {
            setLoading(false);
            setData(null);
        } else {
            try {
                const response = await getAllReports({
                    id: phoneNumber,
                    startDate: formatDate(startDate),
                    endDate: formatDate(endDate),
                    role: 'user',
                });
                setLoading(false);
                if (response && response?.success) {
                    const filteredData = response?.data?.filter(row =>
                        !row.interpretation_request_status
                        || row.interpretation_request_status === "requested"
                        || row.interpretation_request_status === "in progress"
                        || row.interpretation_request_status === "completed"
                    );

                    const mappedData = filteredData?.map(row => {
                        return {
                            ...row,
                            interpretation_data_logs: row?.interpretation_completed_at ? [{ interpreter_details: row?.interpreter_details, completed_at: parseInt(row?.interpretation_completed_at) }, ...(row?.interpretation_data_logs || [])] : []
                        }
                    });
                    setData(mappedData || []);
                    setDataCount(response?.counts);
                    sortingData(mappedData || []);
                    const searchDetails = {
                        searchStatus: status,
                        searchStDate: startDate,
                        searchEnDate: endDate,
                        searchResult: mappedData
                    };
                    window.sessionStorage.setItem("searchDetails", JSON.stringify(searchDetails));
                } else {
                    if (response) {
                        enqueueSnackbar(response.message, { variant: 'error' })
                    }
                    setLoading(false);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
                setLoading(false);
            }
        }
    };
    const formatDate = (date) => {
        return date ? dayjs(date).format('DD MMM, YYYY') : null;
    };

    function sortingData(reportData) {
        //--------------------------------------------//
        let filteredUsers;
        if (status === "completed" || status === "") {
            filteredUsers = applySortFilter(
                reportData.filter(row => {
                    if (interpretationStatusFilter === 'all') {
                        return true;
                    } else {
                        return row?.interpretation_report_status === interpretationStatusFilter;
                    }
                }),
                getComparator(order, orderBy),
                filterName
            );
        } else {
            filteredUsers = applySortFilter(reportData, getComparator(order, orderBy), filterName);
        }

        const dataRows = filteredUsers.map((row, index) => {
            return mapRowData(row);
        });
        setRows(dataRows)
    }

    const mapRowData = (row) => {
        return {
            id: `${row?.id}`,
            searchname: `${row?.user_data?.first_name} ${row?.user_data?.last_name}, ${row?.user_data?.phone_number}`,
            searchreportType: `${row?.report_type?.replace(/REPORT|_/g, ' ')}`,
            searchfindings: row?.interpretation_report_status || row?.conclusions?.ecg_type,
            searchecg_status: row?.interpretation_request_status,
            searchtakenAt: row?.report_timestamp ? parseInt(row?.report_timestamp) : '',
            searchrequestedAt: row?.interpretation_requested_at ? parseInt(row?.interpretation_requested_at) : '',
            searchcompletedAt: row?.interpretation_completed_at ? parseInt(row?.interpretation_completed_at) : '',
            conclusions: `${row?.conclusions?.ecg_type}`,
            name: <TableCell sx={{ border: 'unset' }} component="div" scope="row" padding="none">
                <Stack direction="row" alignItems="center" spacing={2} >
                    <ContentCopy fontSize='small' sx={{ cursor: "pointer" }} onClick={onCopyClick(row?.user_data?.phone_number)} />

                    <Tooltip title={row?.user_data?.phone_number}>

                        <OverflowTypography maxWidth={150} variant="subtitle2" noWrap>
                            {row?.user_data?.first_name} {row?.user_data?.last_name}
                        </OverflowTypography>
                    </Tooltip>
                </Stack>
            </TableCell>,
            reportType:
                <TableCell sx={{ border: 'unset' }} component="div" scope="row" padding="none">
                    <Stack direction="row" alignItems="center" spacing={2} >
                        <Box sx={{ m: 1, position: 'relative' }}>
                            <PictureAsPdfIcon fontSize='small' sx={{ cursor: "pointer" }} onClick={(e) => onlyViewPdf(e, row)} />
                        </Box>
                        <OverflowTypography maxWidth={150} variant="subtitle2" noWrap>
                            {row?.report_type?.replace(/REPORT|_/g, ' ')}
                        </OverflowTypography>

                    </Stack>
                </TableCell>
            ,
            takenAt: <Tooltip title={(row?.report_timestamp ? dayjs(parseInt(row?.report_timestamp)).format('DD MMM, YYYY hh:mm A') : '')}>
                <span>{(row?.report_timestamp ? dayjs(parseInt(row?.report_timestamp)).format('DD MMM hh:mm A') : '')}</span>
            </Tooltip>,
            requestedAt: <Tooltip title={(row?.interpretation_requested_at ? dayjs(parseInt(row?.interpretation_requested_at)).format('DD MMM, YYYY hh:mm A') : '--')}>
                <span>{(row?.interpretation_requested_at ? dayjs(parseInt(row?.interpretation_requested_at)).format('DD MMM hh:mm A') : '--')}</span>
            </Tooltip>,
            completedAt: <Tooltip title={(row?.interpretation_completed_at ? dayjs(parseInt(row?.interpretation_completed_at)).format('DD MMM, YYYY hh:mm A') : '--')}>
                <span>{(row?.interpretation_completed_at ? dayjs(parseInt(row?.interpretation_completed_at)).format('DD MMM hh:mm A') : '--')}</span>
            </Tooltip>,
            findings_status: status === "completed" || !status ? (
                <Typography
                    color={
                        row?.interpretation_report_status === 'normal'
                            ? 'success.main'
                            : row?.interpretation_report_status === 'abnormal'
                                ? 'warning.main'
                                : row?.interpretation_report_status === 'critical'
                                    ? 'error.main'
                                    : row?.interpretation_report_status === 'retake'
                                        ? 'info.main'
                                        : 'default'
                    }
                >
                    {row?.interpretation_report_status === 'normal'
                        ? 'Normal'
                        : row?.interpretation_report_status === 'abnormal'
                            ? 'Abnormal'
                            : row?.interpretation_report_status === 'critical'
                                ? 'Critical'
                                : row?.interpretation_report_status === 'retake'
                                    ? 'Retake'
                                    : ' '}
                </Typography>
            ) : null,
            ecg_status:
                row?.selected || row?.interpretation_request_status === "in progress" ? (
                    <Tooltip color="warning" title="In progress">
                        <Label color="warning">
                            <ScheduleOutlined sx={{ mr: 1 }} fontSize="small" color="warning" severity="warning" />
                            <OverflowTypography maxWidth={100} variant="subtitle2" noWrap>
                                {row?.interpreter_details?.name ? `By: ${row?.interpreter_details?.name}` : 'In progress'}
                            </OverflowTypography>
                        </Label>
                    </Tooltip>
                ) : row?.interpretation_request_status === "completed" ? (
                    <Tooltip color="success" title="Interpreted">
                        <Label color="success">
                            <TaskAltOutlined sx={{ mr: 1 }} fontSize="small" color="success" />
                            <OverflowTypography maxWidth={100} variant="subtitle2" noWrap>
                                {`By: ${row?.interpreter_details?.name || 'Unknown'}`}
                            </OverflowTypography>
                        </Label>
                    </Tooltip>
                ) : row?.interpretation_request_status ? (
                    <Tooltip color="primary" title="New request">
                        <Label color="primary">
                            {row?.interpretation_request_status}
                        </Label>
                    </Tooltip>
                ) : (
                    null
                )
            ,
            action: row?.interpretation_request_status === "in progress" ? (
                row?.interpreter_phone_number === account?.phoneNumber ? (
                    <>
                    </>
                ) : "--"
            ) : row?.interpretation_request_status === 'completed' && dayjs(row?.interpretation_completed_at).isBefore(dayjs().subtract(1, 'minute')) ? (
                <>
                    <Tooltip title="Download" arrow>
                        <LoadingButton
                            variant="contained"
                            onClick={(event) => downloadPDF(event, row, 'download')}
                        >
                            <Download />
                        </LoadingButton>
                    </Tooltip>
                </>
            )
                : (
                    <>
                    </>
                ),
            report_status: row?.interpretation_request_status,
            phone_number: row?.interpreter_phone_number,
            user_data: row?.user_data,
            interpreter_details: row?.interpreter_details,
            selected: row?.selected,
            rowsData: row,
            isNew: row?.isNew,
        };
    }

    //--------------------------------------------//

    const downloadPDF = async (e, row) => {
        e.stopPropagation();
        const id = row?.id;
        setRowLoading(id);
        try {
            const reportresponse = await getEcgData({ id });
            const reportdata = reportresponse?.data;
            const fileName = `${reportdata?.user_data?.first_name}-${reportdata?.user_data?.last_name}-${dayjs(reportdata?.report_timestamp).format('DD-MM-YYYY-HH-mm')}${reportdata?.interpretation_data?.report_status ? `-${reportdata?.interpretation_data?.report_status}` : ''}`;
            downloadFromURI(`data:application/pdf;base64,${reportdata?.ReportPdf}`, fileName)
            setRowLoading(null);
            enqueueSnackbar("Pdf download suceessfully", { variant: 'success' })
        } catch (error) {
            enqueueSnackbar(error, { variant: 'error' })
        }
    };
    const onlyViewPdf = async (e, row) => {
        try {
            e.stopPropagation();
            const id = row?.id
            setRowId(id);
            if (row?.interpretation_request_status == 'requested') {
                getEcgReportAndData({
                    id
                }).then(reportData => {
                    const fetchedPdfData = reportData?.data?.ReportPdf
                    if (!fetchedPdfData) {
                        setOpenViewPdfDialog(false)
                        enqueueSnackbar("Some error occurred while fetching pdf", { variant: 'error' })
                    } else {
                        const URL = `data:application/pdf;base64,${fetchedPdfData}`;
                        setPdfData(reportData?.data)
                        setPdf(URL);
                    }
                })
            } else {
                setopenLink(true)
                getEcgData({ id }).then(reportData => {
                    const fetchedPdfData = reportData?.data?.ReportPdf
                    if (!fetchedPdfData) {
                        setOpenViewPdfDialog(false)
                        enqueueSnackbar("Some error occurred while fetching pdf", { variant: 'error' })
                    } else {
                        const URL = `data:application/pdf;base64,${fetchedPdfData}`;
                        setPdfData(reportData?.data)
                        setPdf(URL);
                    }

                })
            }
            setOpenViewPdfDialog(true)
        } catch (error) {
            enqueueSnackbar(error, { variant: 'error' })
        }
    }

    const onCopyClick = (text) => (e) => {
        e.stopPropagation();
        copyToClipboard(text);
        enqueueSnackbar("Copied to clipboard.", { variant: 'success' })
    };
    const handleChange = (event) => {
        setLoading(true);
        setInterpretationStatusFilter(event.target.value);
        setLoading(false);
    };

    const StyledBox = styled(Box)({
        '.highlightRow': {
            backgroundColor: '#FFEEBA',
        },
    });
    function NoRowsOverlay() {
        return (
            <Stack height="100%" alignItems="center" justifyContent="center">
                No rows in DataGrid
                <pre>(rows=&#123;[]&#125;)</pre>
            </Stack>
        );
    }
    const handleCloseDialog = useCallback(() => {
        setSelectedRowData(null);
        setIsDialogOpen(false);

    });
    const handleECGLeadCloseDialog = useCallback(() => {
        setSelectedRowData(null);
        setopenECGLeadDialog(false);

    });
    const handleViewRowDetails = (rowId, event) => {
        event.stopPropagation();
        const rowData = data?.find(item => item?.id == rowId);
        if (rowData?.interpretation_completed_at || rowData?.interpretation_requested_at) {
            setSelectedRowData(rowData);
            setIsDialogOpen(true);
        }
    };

    const handleGridRowClick = async (data, event) => {
        event.stopPropagation();
        setSelectedData(data?.rowsData);
        setopenECGLeadDialog(true);

    }
    const handleSortModalChange = (model) => {
        setSortModel(model)
    }
    const handleECGCloseViewPdfDialog = () => {
        setopenECGLeadDialog(false);
    }
    const handleCloseViewPdfDialog = () => {
        setOpenViewPdfDialog(false);
        setPdf(null);
        setopenLink(false)

    }
    const downloadAppPdf = async () => {
        try {
            const reportResponse = await getEcgReportAndData({
                id: rowId
            })

            if (!reportResponse || reportResponse.status === 400) {
                throw new Error('Failed to fetch report data');
            }
            const reportData = reportResponse?.data;
            const fetchedPdfData = reportData?.ReportPdf;
            const uri = `data:application/pdf;base64,${fetchedPdfData}`;
            const fileName = `ecg-report-${reportData?.user_data?.first_name}${reportData?.user_data?.last_name ? `-${reportData?.user_data?.last_name}` : ''}-${dayjs(reportData?.report_timestamp).format('DD-MM-YYYY-HH-mm')}${reportData?.interpretation_data?.report_status ? `-${reportData?.interpretation_data?.report_status}` : ''}`;
            downloadFromURI(uri, fileName)
            enqueueSnackbar("Pdf downloading successfully", { variant: 'success' })

        }
        catch (error) {
            enqueueSnackbar(error, { variant: 'error' })

        }

    }
    //Download ECG Lead Data
    const handleDownloadButton = async (path, reportType) => {

        try {
            const response = await downloadLeadFile({
                path: [path],
                report_type: reportType,
            });

            response.urls.forEach(url => {

                const link = document.createElement('a');
                link.href = url;
                link.target = '_blank';
                link.download = '';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            })
        }
        catch (e) {
            console.log(e)
        }

    }

    const handleDownloadAll = async () => {
        if (!selectedData || !selectedData.paths) return;

        const paths = Object.keys(selectedData.paths).map(key => selectedData.paths[key]);
        const response = await downloadLeadFile({
            path: paths,
            report_type: selectedData?.report_type,

        });

        response.urls.forEach(url => {

            const link = document.createElement('a');
            link.href = url;
            link.target = '_blank';
            link.download = '';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

        })
    };
    const handleECGleadActionButton = async (data) => {
        setSelectedData(data);
        setopenECGLeadDialog(true);
    }

    const downloadDefaultPdf = () => {
        try {
            if (pdfData) {
                const fileName = `ecg-report-${pdfData?.user_data?.first_name}${pdfData?.user_data?.last_name ? `-${pdfData?.user_data?.last_name}` : ''}-${dayjs(pdfData?.report_timestamp).format('DD-MM-YYYY-HH-mm')}${pdfData?.interpretation_data?.report_status ? `-${pdfData?.interpretation_data?.report_status}` : ''}`;
                downloadFromURI(pdf, fileName)
            }
        }
        catch (e) {
            enqueueSnackbar(error, { variant: 'error' })

        }
    }
    return (
        <>
            <StyledBox >
                <Grid sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginY: '10px',
                    marginBottom: '20px',
                    px: 1,
                    flexDirection: (isSmall ? 'column-reverse' : 'row'),
                    gap: (isSmall ? '1rem' : 0)
                }}>
                    <Grid item xs={12} md={6}>
                        <Box flexDirection="column" gap={2} display="flex" flexWrap="wrap" alignItems="center">
                            <Stack direction="row" spacing={1}>
                                <Typography>Total Results: {rows?.length}</Typography>
                            </Stack>

                        </Box>
                    </Grid>
                    <Grid item xs={12} md={5}>
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "flex-end",
                            }} >
                            <Stack direction="row" spacing={1}>

                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <Box
                                        display="flex"
                                        flexDirection="row"
                                        alignItems="center"
                                        gap={2}
                                    >
                                        <Controller
                                            name="phoneNumber"
                                            control={control}
                                            defaultValue=""
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    label="Phone Number"
                                                    variant="outlined"
                                                    type="tel"
                                                    fullWidth
                                                    placeholder="+91123456789"
                                                    sx={{
                                                        height: '56px',
                                                        width: '22vh',
                                                        '& .MuiInputBase-root': {
                                                            height: '100%',
                                                        },
                                                    }}
                                                />
                                            )}
                                        />
                                        <DatePicker
                                            sx={{ width: (isSmall ? '120px' : (isMd ? '120px' : '150px')) }}
                                            label="Start Date"
                                            value={startDate}
                                            format='DD/MM/YYYY'
                                            maxDate={dayjs(endDate)}
                                            onChange={(date) => handleStartDateChange(date)}
                                        />
                                        <DatePicker
                                            sx={{ width: (isSmall ? '120px' : (isMd ? '120px' : '150px')) }}
                                            label="End Date"
                                            value={endDate}
                                            format='DD/MM/YYYY'
                                            minDate={dayjs(startDate)}
                                            maxDate={dayjs()}
                                            onChange={(date) => handleEndDateChange(date)}
                                        />
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            sx={{
                                                height: '56px',
                                                padding: '0 16px',
                                            }}
                                        >
                                            Submit
                                        </Button>
                                    </Box>
                                </form>

                            </Stack>
                        </Box>
                    </Grid>
                </Grid>
            </StyledBox>
            {loading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '30px' }}>
                    <CircularProgress />
                </Box>
            ) : data ? (
                <Box sx={{ m: 1, p: 1, minHeight: '50vh', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                    <DataGrid
                        autoHeight
                        sx={{ flexGrow: 1, minHeight: '50vh' }}
                        rows={rows}
                        filterMode="client"
                        columns={TABLE_HEAD}
                        disableVirtualization={true}
                        getRowId={(row) => row?.id}
                        onRowClick={(params, event) => handleGridRowClick(params.row, event)}
                        selectionModel={selectionModel}
                        onSelectionModelChange={(newSelectionModel) => setSelectionModel(newSelectionModel)}
                        getRowClassName={(params) => {
                            const isSelected = params.row.selected;
                            return params.row?.isNew
                                ? customStyle?.highlightRowNew
                                : isSelected
                                    ? customStyle?.highlightRowProgress
                                    : '';
                        }}
                        localeText={{ noRowsLabel: 'No interpretation request found.' }}
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 10,
                                },
                            },
                        }}
                        hideFooter={!rows?.length}
                        pageSizeOptions={[10, 15, 20, 25]}
                        slots={{
                            toolbar: () => (
                                <GridToolbarContainer>
                                    <GridToolbarQuickFilter />
                                    <Stack
                                        spacing={3}
                                        flexGrow={1}
                                        direction="row"
                                        alignItems="center"
                                        justifyContent="flex-end"
                                    >
                                        <GridToolbarColumnsButton />
                                        <GridToolbarFilterButton />
                                        <GridToolbarExport
                                            csvOptions={{
                                                fields: [
                                                    'searchname',
                                                    'searchreportType',
                                                    'searchtakenAt',
                                                    'searchrequestedAt',
                                                    'searchfindings',
                                                    'searchecg_status',
                                                ],
                                            }}
                                        />
                                    </Stack>
                                </GridToolbarContainer>
                            ),
                        }}
                        sortModel={sortModel}
                        onSortModelChange={(model) => handleSortModalChange(model)}
                        sortingOrder={['asc', 'desc', null]}
                    />
                </Box>

            ) : null}


            <Dialog open={openViewPdfDialog} onClose={handleCloseViewPdfDialog} maxWidth="md" fullWidth
                PaperProps={{
                    style: {
                        overflow: 'hidden',
                    },
                }}
            >
                <DialogContent style={dialogStyles}>

                    {pdf ? <div>
                        <Stack flexDirection="row" display="flex" justifyContent="space-between" alignItems="center" width="100%" >


                            <Tooltip title="Download" arrow>
                                <LoadingButton
                                    variant="contained"
                                    sx={{ boxShadow: 'none' }}
                                    onClick={() => downloadDefaultPdf()}
                                >
                                    <Download />
                                </LoadingButton>
                            </Tooltip>
                            {openLink && <Link variant="subtitle2" onClick={() => downloadAppPdf()}
                                sx={{
                                    cursor: 'pointer',
                                    color: 'primary.main',
                                    fontWeight: 'bold',
                                    textDecoration: 'none',
                                    '&:hover': { textDecoration: 'underline' }
                                }}>
                                Download app report
                            </Link>}


                            <Button onClick={handleCloseViewPdfDialog} variant="outlined" color="primary">
                                Close
                            </Button>

                        </Stack>



                        <div ref={documentRef} style={styles.documentBlock}>
                            <TransformWrapper
                                initialScale={1}
                                smooth={true}
                                wheel={{ step: 0.1, animationType: 'easeOut' }}
                                pinch={{ step: 0.1, animationType: 'easeOut' }}
                                doubleClick={{ step: 0.1, animationType: 'easeOut' }}
                                onTransformed={(ref, scaleState) => {
                                    handleZoom(scaleState?.scale)
                                }} >
                                <TransformComponent>
                                    <Document
                                        file={pdf}
                                        onLoadSuccess={(data) => {
                                            setTotalPages(data.numPages);

                                        }}
                                    >
                                        {Array.apply(null, Array(totalPages))
                                            .map((x, i) => i + 1)
                                            .map(page => <Page
                                                pageNumber={page}
                                                renderAnnotationLayer={false}
                                                renderTextLayer={false}
                                                width={750}
                                                scale={pdfScale}
                                            />)}

                                    </Document>
                                </TransformComponent>
                            </TransformWrapper>

                        </div>
                    </div> : <CircularProgress></CircularProgress>}
                </DialogContent>

            </Dialog>

            <Dialog open={isDialogOpen} onClose={handleCloseDialog} disableScrollLock={false}>
                <DialogTitle style={{ textAlign: 'center' }}>Report Information</DialogTitle>

                <DialogContent>
                    {(selectedRowData && selectedRowData?.interpretation_data_logs && selectedRowData?.interpretation_data_logs?.length > 0) || (selectedRowData && selectedRowData?.meta_data) ? (

                        <List>
                            <ListItem>
                                <ListItemText
                                    primary={<Typography variant="body1"><strong>Device Name:</strong> {metaData.device_name || 'N/A'}</Typography>}
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemText
                                    primary={<Typography variant="body1"><strong>Device ID:</strong> {metaData.device_id || 'N/A'}</Typography>}
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemText
                                    primary={<Typography variant="body1">
                                        <strong>App Version:</strong> {metaData.app_version || 'N/A'}
                                    </Typography>}
                                />
                            </ListItem>
                            {selectedRowData?.interpretation_data_logs && selectedRowData?.interpretation_data_logs.length > 0 && (
                                <List>
                                    <ListItem>
                                        <ListItemText
                                            primary={<Typography variant="body1">
                                                <strong>Interpretation History -:</strong>
                                            </Typography>}
                                        />
                                    </ListItem>
                                    {selectedRowData?.interpretation_data_logs?.map((item, index) => (
                                        <ListItem key={index} style={{ paddingTop: '0 ' }}>
                                            <ListItemText
                                                primary={item?.interpreter_details?.name}
                                                secondary={dayjs(item?.completed_at).format('DD MMM, YYYY hh:mm A')}
                                            />
                                        </ListItem>
                                    ))}
                                </List>

                            )

                            }

                        </List>
                    ) : (
                        <Typography>No history available</Typography>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={openECGLeadDialog} onClose={handleECGCloseViewPdfDialog} maxWidth="md" fullWidth sx={{ '& .MuiDialog-paper': { height: '50vh' } }}>
                <DialogTitle>Download Lead Files</DialogTitle>
                <Box align="right" sx={{ position: 'absolute', top: '10px', right: '0' }}>
                    <Button
                        variant="contained"
                        color="secondary"
                        startIcon={<DownloadForOfflineIcon />}
                        onClick={handleDownloadAll}
                        sx={{ marginLeft: 2, marginRight: 2, padding: '8px 16px', fontSize: '14px' }}
                    >
                        Download All
                    </Button>
                </Box>
                <DialogContent>
                    {selectedData && (
                        <Box sx={{ width: '100%' }}>
                            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                {Object.keys(leads).map((key) => {
                                    const leadLabel = leads[key];
                                    const leadData = selectedData?.paths?.[key];
                                    if (!leadData) {
                                        return null;
                                    }

                                    return (
                                        <Grid item xs={6} key={key}>
                                            <Item>
                                                <Typography variant="subtitle2" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                    <strong>{leadLabel}</strong>
                                                    <Box>
                                                        <Button
                                                            variant="contained"
                                                            color="primary"
                                                            target="_blank"
                                                            startIcon={<DownloadForOfflineIcon />}
                                                            download
                                                            sx={{ marginLeft: 2 }}
                                                            onClick={() => handleDownloadButton(leadData, selectedData?.report_type)}
                                                        >
                                                            Download
                                                        </Button>
                                                    </Box>
                                                </Typography>
                                            </Item>
                                        </Grid>
                                    );
                                })}
                            </Grid>
                        </Box>
                    )
                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleECGCloseViewPdfDialog}>Close</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}