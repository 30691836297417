export default function DevicetestData() {
    const data = [
        {
          "channelDeviceIds": "SPLG-DN01-2403120639",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120621",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120377",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120109",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120702",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120726",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120675",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120657",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120666",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120667",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120668",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120671",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120655",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120633",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120638",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120647",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120641",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120629",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120634",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120637",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120670",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120674",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120659",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120770",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120744",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120780",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120656",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120662",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120774",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120731",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120729",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120664",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120651",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120734",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120676",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120714",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120713",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120682",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120722",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120715",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120723",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120669",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120665",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120658",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120660",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120663",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120635",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120661",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120719",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120720",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120421",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120424",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120626",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120620",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120293",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120131",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120624",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120602",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120623",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120800",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120694",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120783",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120824",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120820",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120803",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120815",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120788",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120798",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120790",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120789",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120750",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120809",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120793",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120792",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120727",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120814",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120786",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120350",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120776",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120736",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120766",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120781",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120733",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120811",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120823",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120806",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120784",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120813",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120812",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120775",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120849",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120857",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120835",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120853",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120859",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120865",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120826",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120787",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120653",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120802",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120738",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120758",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120797",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120799",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120805",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120804",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120795",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120818",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120755",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120844",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120870",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120858",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120852",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120860",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120721",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120791",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120822",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120816",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120841",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120855",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120850",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120874",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120759",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120772",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120737",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120801",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120817",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120796",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120819",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120751",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120808",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120764",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120785",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120735",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120742",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120778",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120748",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120810",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120794",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120931",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120622",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120725",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120911",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120942",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120716",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120344",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120968",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120958",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120978",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120949",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120698",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120700",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120619",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120935",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120706",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120117",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120652",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2410240054",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2410240082",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2410240038",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2410240079",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2410240092",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2410240014",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2410240004",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2410240029",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2410240027",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2410240036",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2410240039",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2410240050",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2410240043",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2410240034",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2410240021",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2410240001",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2410240059",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2410240062",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2410240025",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2410240033",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2410240017",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2410240057",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2410240091",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2410240081",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2410240080",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2410240075",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2410240056",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2410240077",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2410240085",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2410240067",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2410240074",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2410240083",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2410240088",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2410240070",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2410240055",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2410240064",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2410240065",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2410240094",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2410240093",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2410240096",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2410240097",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2410240095",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2410240098",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2410240041",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2410240008",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2410240035",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2410240069",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2410240022",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2410240010",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2410240076",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2410240086",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120477",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120476",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120394",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120463",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120470",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120441",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2410240090",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2410240084",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2410240052",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2410240026",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2410240068",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2410240007",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2410240044",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2410240053",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2410240073",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2410240078",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2410240066",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2410240020",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2410240046",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2410240009",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2410240037",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2410240015",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2410240047",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2410240061",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2410240072",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2410240042",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2410240031",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2410240045",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2410240023",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2410240013",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2410240049",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2410240019",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2410240087",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2410240089",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2410240058",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2410240063",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2410240060",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120483",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120465",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120466",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120480",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2410240018",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2410240051",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2410240002",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2410240030",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2410240028",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2410240016",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2410240024",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2410240003",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2410240005",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120481",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120468",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120460",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2410240006",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2410240012",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2410240011",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2410240032",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120467",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120461",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120458",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120459",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120469",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120444",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120457",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120472",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2410240048",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120454",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120455",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120462",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120446",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120448",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120442",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120439",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120451",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120452",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120453",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120450",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120478",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120479",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120471",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120393",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120474",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120445",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120392",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120449",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120391",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120438",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120473",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2410240040",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120384",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120365",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120369",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120366",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120376",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120380",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120385",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120370",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120381",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120378",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120368",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120956",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120336",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120964",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120971",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120388",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120390",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120977",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120944",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120934",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120974",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120966",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120963",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120927",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120909",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120941",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120026",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120825",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2403120645",
          "deviceType": "Spandan-Legacy",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2407290047",
          "deviceType": "Spandan-Legacy-RF",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2407290101",
          "deviceType": "Spandan-Legacy-RF",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2407290032",
          "deviceType": "Spandan-Legacy-RF",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2407290017",
          "deviceType": "Spandan-Legacy-RF",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2407290068",
          "deviceType": "Spandan-Legacy-RF",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2407290005",
          "deviceType": "Spandan-Legacy-RF",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2407290082",
          "deviceType": "Spandan-Legacy-RF",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2407290041",
          "deviceType": "Spandan-Legacy-RF",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2407290009",
          "deviceType": "Spandan-Legacy-RF",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2407290011",
          "deviceType": "Spandan-Legacy-RF",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2407290098",
          "deviceType": "Spandan-Legacy-RF",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2407290030",
          "deviceType": "Spandan-Legacy-RF",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2407290026",
          "deviceType": "Spandan-Legacy-RF",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2407290046",
          "deviceType": "Spandan-Legacy-RF",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2407290076",
          "deviceType": "Spandan-Legacy-RF",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2407290071",
          "deviceType": "Spandan-Legacy-RF",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2407290057",
          "deviceType": "Spandan-Legacy-RF",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2407290003",
          "deviceType": "Spandan-Legacy-RF",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2407290061",
          "deviceType": "Spandan-Legacy-RF",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2407290006",
          "deviceType": "Spandan-Legacy-RF",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2407290004",
          "deviceType": "Spandan-Legacy-RF",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2407290066",
          "deviceType": "Spandan-Legacy-RF",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2407290089",
          "deviceType": "Spandan-Legacy-RF",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2407290040",
          "deviceType": "Spandan-Legacy-RF",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2407290058",
          "deviceType": "Spandan-Legacy-RF",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2407290053",
          "deviceType": "Spandan-Legacy-RF",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2407290085",
          "deviceType": "Spandan-Legacy-RF",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2407290025",
          "deviceType": "Spandan-Legacy-RF",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2407290095",
          "deviceType": "Spandan-Legacy-RF",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2407290087",
          "deviceType": "Spandan-Legacy-RF",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2407290008",
          "deviceType": "Spandan-Legacy-RF",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2407290012",
          "deviceType": "Spandan-Legacy-RF",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2407290045",
          "deviceType": "Spandan-Legacy-RF",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2407290021",
          "deviceType": "Spandan-Legacy-RF",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2407290036",
          "deviceType": "Spandan-Legacy-RF",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2407290037",
          "deviceType": "Spandan-Legacy-RF",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2407290060",
          "deviceType": "Spandan-Legacy-RF",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2407290080",
          "deviceType": "Spandan-Legacy-RF",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2407290001",
          "deviceType": "Spandan-Legacy-RF",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2407290015",
          "deviceType": "Spandan-Legacy-RF",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2407290024",
          "deviceType": "Spandan-Legacy-RF",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2407290063",
          "deviceType": "Spandan-Legacy-RF",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2407290044",
          "deviceType": "Spandan-Legacy-RF",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2407290048",
          "deviceType": "Spandan-Legacy-RF",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2407290016",
          "deviceType": "Spandan-Legacy-RF",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2407290050",
          "deviceType": "Spandan-Legacy-RF",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2407290014",
          "deviceType": "Spandan-Legacy-RF",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2407290031",
          "deviceType": "Spandan-Legacy-RF",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2407290029",
          "deviceType": "Spandan-Legacy-RF",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2407290051",
          "deviceType": "Spandan-Legacy-RF",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2407290074",
          "deviceType": "Spandan-Legacy-RF",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2407290093",
          "deviceType": "Spandan-Legacy-RF",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2407290069",
          "deviceType": "Spandan-Legacy-RF",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2407290084",
          "deviceType": "Spandan-Legacy-RF",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2407290056",
          "deviceType": "Spandan-Legacy-RF",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2407290022",
          "deviceType": "Spandan-Legacy-RF",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2407290070",
          "deviceType": "Spandan-Legacy-RF",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2407290083",
          "deviceType": "Spandan-Legacy-RF",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2407290065",
          "deviceType": "Spandan-Legacy-RF",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2407290039",
          "deviceType": "Spandan-Legacy-RF",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2407290096",
          "deviceType": "Spandan-Legacy-RF",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2407290023",
          "deviceType": "Spandan-Legacy-RF",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2407290075",
          "deviceType": "Spandan-Legacy-RF",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2407290064",
          "deviceType": "Spandan-Legacy-RF",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2407290035",
          "deviceType": "Spandan-Legacy-RF",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2407290034",
          "deviceType": "Spandan-Legacy-RF",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2407290027",
          "deviceType": "Spandan-Legacy-RF",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2407290018",
          "deviceType": "Spandan-Legacy-RF",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2407290013",
          "deviceType": "Spandan-Legacy-RF",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2407290078",
          "deviceType": "Spandan-Legacy-RF",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPLG-DN01-2407290094",
          "deviceType": "Spandan-Legacy-RF",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPNE-DN01-2408080090",
          "deviceType": "Spandan-Neo",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPNE-DN01-2408080002",
          "deviceType": "Spandan-Neo",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPNE-DN01-2408080051",
          "deviceType": "Spandan-Neo",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPNE-DN01-2408080046",
          "deviceType": "Spandan-Neo",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPNE-DN01-2408080068",
          "deviceType": "Spandan-Neo",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPNE-DN01-2408080032",
          "deviceType": "Spandan-Neo",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPNE-DN01-2408080030",
          "deviceType": "Spandan-Neo",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPNE-DN01-2408080004",
          "deviceType": "Spandan-Neo",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPNE-DN01-2408080044",
          "deviceType": "Spandan-Neo",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPNE-DN01-2408080071",
          "deviceType": "Spandan-Neo",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPNE-DN01-2408080023",
          "deviceType": "Spandan-Neo",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPNE-DN01-2408080073",
          "deviceType": "Spandan-Neo",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPNE-DN01-2408080065",
          "deviceType": "Spandan-Neo",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPNE-DN01-2408080033",
          "deviceType": "Spandan-Neo",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPNE-DN01-2408080050",
          "deviceType": "Spandan-Neo",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPNE-DN01-2408080092",
          "deviceType": "Spandan-Neo",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPNE-DN01-2408080086",
          "deviceType": "Spandan-Neo",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPNE-DN01-2408080094",
          "deviceType": "Spandan-Neo",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPNE-DN01-2408080079",
          "deviceType": "Spandan-Neo",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPPR-DN01-2411040304",
          "deviceType": "Spandan-Pro",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPPR-DN01-2411040315",
          "deviceType": "Spandan-Pro",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPPR-DN01-2411040302",
          "deviceType": "Spandan-Pro",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPPR-DN01-2411040306",
          "deviceType": "Spandan-Pro",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPPR-DN01-2411040313",
          "deviceType": "Spandan-Pro",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPPR-DN01-2411040058",
          "deviceType": "Spandan-Pro",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPPR-DN01-2411040055",
          "deviceType": "Spandan-Pro",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPPR-DN01-2411040060",
          "deviceType": "Spandan-Pro",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPPR-DN01-2411040174",
          "deviceType": "Spandan-Pro",
          "channel": "FBA"
        },
        {
          "channelDeviceIds": "SPPR-DN01-2407010093",
          "deviceType": "Spandan-Pro",
          "channel": "FBA"
        }
    ];
    return data;
}
