
import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';

// @mui
import {
  Box,
  Stack,
  Container,
  Typography,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  FormHelperText,
  CircularProgress,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  TextField,
  Tooltip,
  FormControlLabel,
  Checkbox,
  Chip

} from '@mui/material';
import dayjs from "dayjs";
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { height, styled } from '@mui/system';
import { useSnackbar } from '../components/snackbar';
import { getExtendExisitingPlanUsers } from 'api/users';
import useResponsive from '../hooks/useResponsive';
import { useAuthDispatch } from 'components/AuthStateProvider';

import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { useForm, Controller } from "react-hook-form";

import { getUserExtendPlan } from 'api/users';

const CustomToolbar = () => {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
};
//-----------------------------------------------------------------------

export default function ExtendPlanUsers() {
  const { enqueueSnackbar } = useSnackbar();

  const { searchStatus, searchStDate, searchEnDate, searchResult } = JSON.parse(window.sessionStorage.getItem("searchDetails") || '{}');
  const isLg = useResponsive('up', 'md');

  const isMd = useResponsive('between', 'xs', 'md');

  const isSmall = useResponsive('', 'xs');
  const { account } = useAuthDispatch();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [isExpandDialogOpen, setisExpandDialogOpen] = useState(false);
  const [startDate, setStartDate] = useState(searchStDate ? dayjs(searchStDate) : dayjs());
  const [endDate, setEndDate] = useState(searchEnDate ? dayjs(searchEnDate) : dayjs());
  const [findReportphoneNumber, setfindReportphoneNumber] = useState("");
  const [isUserName, setisUserName] = useState("");
  const [customerId, setcustomerId] = useState(null);
  const [selectedChip, setSelectedChip] = useState(null);
  const [isFormOpen, setisFormOpen] = useState(false)
  const { control, handleSubmit } = useForm();
  const { control: mainFormControl, handleSubmit: mainFormHandleSubmit, formState: { errors: mainFormErrors } } = useForm();
  const { control: extendPlanFormControl, handleSubmit: extendPlanHandleSubmit, formState: { errors: extendPlanErrors } } = useForm();


  const onSubmit = async (data) => {
    const { phoneNumber } = data;
    const start = startDate.format('YYYY-MM-DD');
    const end = endDate.format('YYYY-MM-DD');
    setfindReportphoneNumber(data?.phoneNumber);
    setLoading(true);

    setisUserName(null);
    try {
      const response = await getExtendExisitingPlanUsers({ phoneNumber, startDate: start, endDate: end });

      setisUserName(response?.userName);
      if (response?.success) {
        setData(response?.data || []);
        setFilteredData(response?.data || []);
        if (!response?.data?.length) setisFormOpen(true);
      } else {
        setData([]);
        setFilteredData([]);
        setisFormOpen(false);
        enqueueSnackbar(response?.message, { variant: 'error' });
      }
    } catch (e) {
      enqueueSnackbar("Unable to fetch data.", { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const StyledBox = styled(Box)({
    '.highlightRow': {
      backgroundColor: '#FFEEBA',
    },
  });


  const handleEndDateChange = (date) => {
    setEndDate(date);
  };
  const handleformCloseDialog = () => {
    setisFormOpen(false);
  }

  const onClickExpendData = (row, event) => {
    event.stopPropagation();
    setisExpandDialogOpen(true);
    setSelectedRow(row);
    setcustomerId(row?.customerIds)
  };


  const handleExpandCloseDialog = () => {
    setisExpandDialogOpen(false);
    setSelectedRow(null);
  };
  const handleChipClick = (months) => {
    setSelectedChip(months);
    const newEndDate = dayjs().add(months, 'month');
    handleEndDateChange(newEndDate);
  };

  const handleMainFormSubmit = async (data) => {
    const { phoneNumber, name, email, created_at, expiry_date, plan_name, plan_status, remarks } = data;
    try {
      const response = await getUserExtendPlan({ phoneNumber, name, email, created_at, expiry_date, plan_name, subscription_type: plan_status, newUser: true, remarks });
      if (response?.success) {
        setisFormOpen(false);
        enqueueSnackbar(response?.message, { variant: 'sucess' });
      }
    } catch (error) {

      enqueueSnackbar(response?.message, { variant: 'sucess' });
    }
  };

  const onclickHandleNewPlan = (row, event) => {
    setisFormOpen(true);
  }

  const handleExtendPlanSubmit = async (data) => {
    const subscriptionType = selectedRow?.subscription_type;

    const { extended_date, expiry_date, plan_name, phoneNumber , remarks} = data;
  
    const parsedExpiryDate = dayjs(expiry_date, "YYYY-MM-DDTHH:mm:ss.SSSZ");
    const parsedExtendedDate = dayjs(extended_date, "YYYY-MM-DDTHH:mm:ss.SSSZ");
    if (!parsedExpiryDate.isValid() || !parsedExtendedDate.isValid()) {
      enqueueSnackbar("Invalid dates provided.", { variant: "error" });
      return;
    }

    const additionalDays = parsedExtendedDate.diff(parsedExpiryDate, "day");
    const updatedExpiryDate = parsedExpiryDate
      .add(additionalDays, "day")
      .format("YYYY-MM-DD");

    try {
      const response = await getUserExtendPlan({
        extended_date,
        expiry_date: updatedExpiryDate,
        plan_name, 
        phoneNumber,
        customer_id: customerId,
        subscription_type: subscriptionType,
        remarks
      });

      if (response?.success) {
        setisExpandDialogOpen(false);
        enqueueSnackbar(response?.message, { variant: "success" });
      }
    } catch (error) {
      enqueueSnackbar("Failed to extend plan.", { variant: "error" });
    }
  };




  const columns = [
    // { field: 'id', headerName: 'ID', width: 70 },
    { field: 'plan_name', headerName: 'Plan Name', width: 150 },
    {
      field: 'created_at',
      headerName: 'Created At',
      width: 200,
      renderCell: (params) => {
        return dayjs(params.row?.created_at, 'DD/MM/YYYY HH:mm:ss').format('DD MMM YY');
      }
    },
    {
      field: 'expiry_date',
      headerName: 'Expiry Date',
      width: 200,
      renderCell: (params) => {
        return dayjs(params.row?.expiry_date, 'DD/MM/YYYY HH:mm:ss').format('DD MMM YY');
      }
    },
    { field: 'subscription_type', headerName: 'Subscription Type', width: 150 },
    {
      field: 'status', headerName: 'Status', width: 120, renderCell: (params) => {
        return (
          <Typography color={params.row.status === 'active' || params.row.status === 'created' ? 'green' : 'red'}>
            {params.row.status}
          </Typography>
        );
      },
    },
    {
      field: 'Action',
      headerName: 'Action',
      width: 250,
      hide: false,
      renderCell: (params) => {
        // Check if this is the first row (index 0)
        if (params.row.id === 1) { // Index 0, in the rows mapping this will be id=1
          return (
            <div style={{ display: 'flex', gap: '8px' }}>
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={(event) => onClickExpendData(params.row, event)}
              >
                Extend
              </Button>
              <Button
                variant="contained"
                color="secondary"
                size="small"
                onClick={(event) => onclickHandleNewPlan(params.row, event)}
              >
                Add New
              </Button>
            </div>
          );
        }
        return null; // Return null if this is not the first row
      },
    }
  ];
  
  const rows = filteredData.map((row, index) => ({
    id: index + 1, 
    customerIds: row?.id,
    plan_name: row?.plan_name,
    phone_number: row?.phone_number,
    created_at: dayjs(row?.created_at).format('DD/MM/YYYY HH:mm:ss'),
    expiry_date: dayjs(row?.expiry_date).format('DD/MM/YYYY HH:mm:ss'),
    subscription_type: row?.subscription_type,
    last_payment_id: row?.last_payment?.id,
    status: row?.subscription_status,
    rowId: row?.id,
    payment_id: row?.last_payment?.id ?? 'N/A',
    Action: row,
  }));
  

  return (
    <>
      <Helmet>
        <title> Active Users</title>
      </Helmet>
      <Container sx={{ marginTop: isLg ? 0 : 1 }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
          <Typography variant="h4" gutterBottom>
            Update Subscription
          </Typography>
          <StyledBox >
            <Grid sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginY: '10px',
              marginBottom: '20px',
              px: 1,
              flexDirection: (isSmall ? 'column-reverse' : 'row'),
              gap: (isSmall ? '1rem' : 0)
            }}>

              <Grid item xs={12} md={5}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }} >
                  <Stack direction="row" spacing={1}>

                    <form onSubmit={handleSubmit(onSubmit)}>
                      <Box
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        gap={2}
                      >
                        <Controller
                          name="phoneNumber"
                          control={control}
                          defaultValue=""
                          render={({ field }) => (
                            <TextField
                              {...field}
                              label="Phone Number"
                              variant="outlined"
                              type="tel"
                              fullWidth
                              placeholder="+91123456789"
                              sx={{
                                height: '56px',
                                width: '22vh',
                                '& .MuiInputBase-root': {
                                  height: '100%',
                                },
                              }}
                            />
                          )}
                        />

                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          sx={{
                            height: '56px',
                            padding: '0 16px',
                          }}
                        >
                          Search
                        </Button>
                      </Box>
                    </form>

                  </Stack>
                </Box>
              </Grid>
            </Grid>
          </StyledBox>
        </Stack>
        {isUserName && <Typography sx={{ paddingBottom: 2 }} >User: <b>{ isUserName }</b></Typography> }
        <Paper sx={{ height: 550, width: '100%' }}>
          {loading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '30px', alignItems: 'center' }}>
              <CircularProgress />
            </Box>
          ) : (
            <DataGrid
              rows={rows}
              columns={columns}
              getRowClassName={(params) =>
                params.row.last_payment_id ? 'highlighted-row' : ''
              }

              sx={{
                '& .MuiDataGrid-row.highlighted-row': {
                  backgroundColor: '#e0f7fa !important',
                },

              }}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 50,
                  },
                },
              }}
              components={{
                Toolbar: CustomToolbar
              }}
              pageSizeOptions={[50]}
              // checkboxSelection
              disableRowSelectionOnClick
            // onRowClick={handleRowClick}

            />
          )}
        </Paper>
        <Dialog
          open={isExpandDialogOpen}
          onClose={handleExpandCloseDialog}
          sx={{
            '& .MuiDialog-paper': {
              width: '35vw',
              maxWidth: '90%',
              height: '70vh',
              display: 'flex',
              // justifyContent:'center',
              alignContent: 'center'
            },
          }}
        >
          <DialogTitle>Extend Plan</DialogTitle>
          <DialogContent >
            {selectedRow && (
              <form style={{ height: '100%' }} onSubmit={extendPlanHandleSubmit(handleExtendPlanSubmit)}>
                <Box display='flex' flexDirection='column' justifyContent='space-between' paddingTop={2} sx={{ height: '100%' }}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 2,
                      maxWidth: '100vw',
                    }}
                  >
                    <Controller
                      name="phoneNumber"
                      control={extendPlanFormControl}
                      defaultValue={findReportphoneNumber}
                      rules={{ required: 'Phone number is required' }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label="Phone Number"
                          variant="outlined"
                          fullWidth
                          disabled
                          error={!!extendPlanErrors.phoneNumber}
                          helperText={extendPlanErrors.phoneNumber ? extendPlanErrors.phoneNumber.message : ''}
                        />
                      )}
                    />

                    <Controller
                      name="plan_name"
                      control={extendPlanFormControl}
                      rules={{ required: 'Plan is required' }}
                      defaultValue={selectedRow?.Action?.identifier}
                      render={({ field }) => (
                        <FormControl fullWidth variant="outlined">
                          <InputLabel>Subscription Plan</InputLabel>
                          <Select
                            {...field}
                            label="Subscription Plan"
                          >
                            <MenuItem value="SPN_CLINIC">CLINIC</MenuItem>
                            <MenuItem value="SPN_PREMIUM">PREMIUM</MenuItem>
                          </Select>
                        </FormControl>
                      )}
                    />
                    <Controller
                      name="expiry_date"
                      control={extendPlanFormControl}
                      defaultValue={dayjs(selectedRow?.expiry_date, 'DD/MM/YYYY HH:mm:ss') || null}
                      render={({ field }) => (
                        <DatePicker
                          {...field}
                          label="Previous Expiry Date"
                          value={dayjs(selectedRow?.expiry_date, 'DD/MM/YYYY HH:mm:ss') || null}
                          format="DD/MM/YYYY"
                          onChange={(date) => {
                            field.onChange(date);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              fullWidth
                              InputProps={{
                                ...params.InputProps,
                                readOnly: true,
                              }}
                              disabled
                            />
                          )}
                          disableOpenPicker
                        />
                      )}
                    />
                    <Controller
                      name="extended_date"
                      control={extendPlanFormControl}
                      rules={{ required: 'End Date is required' }}
                      render={({ field }) => (
                        <DatePicker
                          {...field}
                          label="Select Next Expiry Date"
                          value={ selectedRow?.expiry_date ? dayjs(selectedRow?.expiry_date, 'DD/MM/YYYY HH:mm:ss').add(1, 'month') : endDate}
                          format="DD/MM/YYYY"
                          minDate={dayjs()}
                          onChange={(date) => {
                            field.onChange(date);
                            handleEndDateChange(date);
                          }}
                          renderInput={(params) => <TextField {...params} fullWidth />}
                        />
                      )}
                    />
                    <Controller
                      name="remarks"
                      control={extendPlanFormControl}
                      // defaultValue={findReportphoneNumber}
                      rules={{ required: 'Remarks is required' }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label="Remarks"
                          variant="outlined"
                          fullWidth
                          // disabled
                          error={!!extendPlanErrors.remarks}
                          helperText={extendPlanErrors.remarks ? extendPlanErrors.remarks.message : ''}
                        />
                      )}
                    />
                    <Box sx={{ display: 'flex', justifyContent: 'flex-start', gap: '2%' }}>
                      {['1 Month', '3 Months', '6 Months'].map((label) => {
                        const months = parseInt(label.split(' ')[0]);
                        return (
                          <Chip
                            key={label}
                            label={label}
                            onClick={() => handleChipClick(months)}
                            color={selectedChip === months ? 'primary' : 'default'}
                            clickable
                          />
                        );
                      })}
                    </Box>
                  </Box>
                  <Box display='flex' gap={3} justifyContent='flex-end'>
                    <Box>
                      <Button variant="outlined" onClick={handleExpandCloseDialog}>Close</Button>
                    </Box>
                    <Box>
                      <Button type="submit" variant="contained" color="primary">
                        Submit
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </form>
            )}
          </DialogContent>
        </Dialog>
      </Container>
      <Dialog
        open={isFormOpen}
        onClose={handleExpandCloseDialog}
        sx={{
          '& .MuiDialog-paper': {
            width: '35vw',
            height: '80vh',
            display: 'flex',
            alignContent: 'center'
          },
        }}
      >
        <DialogTitle>Add New Plan For User</DialogTitle>
        <DialogContent>
          <form style={{ height:'100%' }} onSubmit={mainFormHandleSubmit(handleMainFormSubmit)}>
            <Box display='flex' flexDirection='column' justifyContent='space-between' sx={{ height: '100%' }}>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, paddingTop: 2 }}>
                <Controller
                  name="phoneNumber"
                  control={mainFormControl}
                  // rules={{ required: 'Phone number is required' }}
                  defaultValue={findReportphoneNumber}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Phone Number"
                      variant="outlined"
                      error={!!mainFormErrors.phoneNumber}
                      helperText={mainFormErrors.phoneNumber ? mainFormErrors.phoneNumber.message : ''}
                      fullWidth
                      disabled
                    />
                  )}
                />
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Controller
                      name="name"
                      control={mainFormControl}
                      // rules={{ required: 'Name is required' }}
                      defaultValue={isUserName}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label="Name"
                          variant="outlined"
                          error={!!mainFormErrors.name}
                          helperText={mainFormErrors.name ? mainFormErrors.name.message : ''}
                          fullWidth
                          disabled
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Controller
                      name="email"
                      control={mainFormControl}
                      rules={{
                        // required: 'Email is required',
                        pattern: {
                          value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                          message: 'Invalid email address',
                        },
                      }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label="Email"
                          variant="outlined"
                          error={!!mainFormErrors.email}
                          helperText={mainFormErrors.email ? mainFormErrors.email.message : ''}
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Controller
                      name="created_at"
                      control={mainFormControl}
                      rules={{
                        required: 'Date/Time is required',
                        validate: (value) => {
                          return value && value.isAfter(dayjs()) ? true : 'Please select a valid start date';
                        },
                      }}
                      render={({ field }) => (
                        <DatePicker
                          {...field}
                          label="Select Start Date"
                          // value={field.value || dayjs()}
                          onChange={(date) => field.onChange(date)}
                          renderInput={(params) => <TextField {...params} fullWidth />}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Controller
                      name="expiry_date"
                      control={mainFormControl}
                      rules={{ required: 'Date/Time is required' }}
                      render={({ field }) => (
                        <DatePicker
                          {...field}
                          label="Select Expiry Date"
                          // value={field.value || dayjs()}
                          onChange={(date) => field.onChange(date)}
                          renderInput={(params) => <TextField {...params} fullWidth />}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
                <Controller
                  name="plan_name"
                  control={mainFormControl}
                  rules={{ required: 'Plan is required' }}
                  render={({ field }) => (
                    <FormControl fullWidth variant="outlined">
                      <InputLabel>Subscription Plan</InputLabel>
                      <Select
                        {...field}
                        label="Subscription Plan"
                      >
                        <MenuItem value="SPN_CLINIC">CLINIC</MenuItem>
                        <MenuItem value="SPN_PREMIUM">PREMIUM</MenuItem>
                      </Select>
                    </FormControl>
                  )}
                />
                <Controller
                  name="remarks"
                  control={mainFormControl}
                  rules={{ required: 'Remarks is required' }}
                  // defaultValue={field.value|| ''}'
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Remarks"
                      variant="outlined"
                      error={!!mainFormErrors.remarks}
                      helperText={mainFormErrors.remarks ? mainFormErrors.remarks.message : ''}
                      fullWidth
                      // disabled
                    />
                  )}
                />
                <Controller
                  name="plan_status"
                  control={mainFormControl}
                  rules={{ required: "Plan Status is required" }}
                  render={({ field }) => (
                    <FormControl fullWidth>
                      <Box sx={{ display: "flex", gap: 2 }}>
                        <Chip
                          label="Trial"
                          color={field.value === "trial" ? "primary" : "default"}
                          size="medium"
                          onClick={() => field.onChange("trial")}
                          sx={{ cursor: "pointer" }}
                        />
                        <Chip
                          label="Paid"
                          color={field.value === "paid" ? "success" : "default"}
                          size="medium"
                          onClick={() => field.onChange("paid")}
                          sx={{ cursor: "pointer" }}
                        />
                      </Box>
                      {mainFormErrors.plan_name && (
                        <Typography color="error" variant="caption">
                          {mainFormErrors.plan_name.message}
                        </Typography>
                      )}
                    </FormControl>
                  )}
                />
              </Box>
              <Box display='flex' gap={3} justifyContent='flex-end'>
                <Box>
                  <Button variant="outlined" onClick={handleformCloseDialog}>Close</Button>
                </Box>
                <Box>
                  <Button type="submit" variant="contained" color="primary">
                    Submit
                  </Button>
                </Box>
              </Box>
            </Box>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
}


